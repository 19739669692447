import axios from "axios";
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");
// List Of Categories
export const add_general_setting= async (values) => {
  let response = await axios.post(baseURL + "/api/general-setting",values);
  return response.data;
}
export const get_general_settings_logs = async () => {
  let response = await axios.get(secureLocalStorage.getItem("baseURL") + "/api/activity-logs-for-general-setting");
  return response.data;
};
