import React, { useEffect, useRef, useState } from 'react'
import { useMutation, useQuery } from 'react-query';
import { add_appointemnt_note, change_appointment_status, delete_appointment, delete_appointment_note, get_all_appointment_notes, get_all_appointments, get_filtered_appointments, update_appointemnt_note } from '../api/appointmentsApi';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { KTIcon, KTSVG } from '../../../../_metronic/helpers';
import { formatDateFlatpickr, formatDateWithTime } from '../../../helper-functions/DateFunction';
import { permissionCheck } from '../../../helper-functions/PermissionCheck';
import { allPeople } from '../../../modules/accounts/components/settings/SettingsModel';
import { get_all_people } from '../../people/api/peopleApi';
import Flatpickr from 'react-flatpickr';

export interface Appointment {
    id: number;
    location_id: string;
    location_type: number | undefined;
    subcategory_id: string | undefined;
    subcategory_title: string | undefined;
    people_id: string | undefined;
    people_name: string | undefined;
    appointment_date_time_slots: {
        datetime: string;
    }[];
    full_name: string | undefined;
    phone_number: string | undefined;
    message: string | undefined;
    email: string | undefined;
    appointment_status: string | undefined;
    payment_status: string | undefined;
    created_at: string | undefined;
    updated_by: string | undefined;
    updated_at: string;
};
interface appointmentNotes {
    id: number;
    appointment_id: number;
    appointment_notes: string | undefined;
}
var appointment_create, appointment_edit, appointment_delete, appointment_activity_log, appointment_view
function AppointmentsList() {
    const [searchInput, setSearchInput] = useState('');
    const [selectedItemsPerPage, setSelectedItemsPerPage] = useState(10); // Step 1: State variable for items per page
    const [sortBy, setSortBy] = useState<{ key: string, ascending: boolean } | null>(null); // State variable for sorting
    const [selectedAppointment, setSelectedAppointment] = useState<Appointment | null>(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [visibleData, setVisibleData] = useState<Appointment[]>([]);
    const [appointmentsList, setAppointmentsList] = useState<Appointment[]>([]);
    const [appointmentId, setAppointmentId] = useState(0);
    const [appointmentsNotesList, setAppointmentsNotesList] = useState<appointmentNotes[]>([]);
    const [newAppointmentNote, setnewAppointmentNote] = useState('');
    const [peopleList, setPeopleList] = useState<allPeople[]>([]);
    const [peopleId, setPeopleId] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [appointmentStatus, setAppointmentStatus] = useState('');
    const [paymentStatus, setPaymentStatus] = useState('');
    const fromDateRef = useRef<Flatpickr>(null); // Ref for the from date Flatpickr instance
    const toDateRef = useRef<Flatpickr>(null);
    appointment_create = permissionCheck('appointment-create')
    appointment_edit = permissionCheck('appointment-update')
    appointment_delete = permissionCheck('appointment-delete')
    appointment_activity_log = permissionCheck('appointment-activity-log')
    appointment_view = permissionCheck('appointment-view')
    const { data: peopleData } = useQuery('peopleList', get_all_people, { refetchOnWindowFocus: false })
    useEffect(() => {
        if (peopleData) {
            setPeopleList(peopleData.data as allPeople[])
        }
    }, [peopleData])
    const { data: allAppointmentsData, isLoading: isAllAppointmentsLoading, isError: isAllAppointmentsError, refetch } = useQuery('appointments', get_all_appointments, { refetchOnWindowFocus: false });
    useEffect(() => {
        if (allAppointmentsData) {
            setAppointmentsList(allAppointmentsData.data as Appointment[])
        }
    }, [allAppointmentsData]);
    const { data: appointmentNotesData, refetch: refetchAppointemntNotes } = useQuery('appointmentNotes', () => get_all_appointment_notes(appointmentId), { refetchOnWindowFocus: false });
    useEffect(() => {
        if (appointmentNotesData) {
            setAppointmentsNotesList(appointmentNotesData.data as appointmentNotes[])
        }
    }, [appointmentNotesData]);
    useEffect(() => {
        if (appointmentId) {
            refetchAppointemntNotes();
        }
    }, [appointmentId]);

    const { data: filterData, refetch: filterRefetchAppointments } = useQuery(
        'filterData',
        () => get_filtered_appointments(peopleId, fromDate, toDate, appointmentStatus, paymentStatus),
        {
            refetchOnWindowFocus: false,
            onSuccess: (filteredData) => {
                setAppointmentsList(filteredData.data);
            },
            onError: (error: any) => {
                // Error handling
                if (error.response) {
                    const errorMessage = error.response.data.message;
                    toast.error(errorMessage, {
                        position: 'top-right',
                        autoClose: 2000,
                    });
                }
            },
            enabled: false, // Set initial enable state to false
        }
    );
    const handleApplyFilters = () => {
        filterRefetchAppointments({ // Enable the query before refetch
            throwOnError: true,
        });
    };
    const handleClearFilters = () => {
        setAppointmentStatus('');
        setFromDate('');
        setToDate('');
        setPeopleId('');
        setPaymentStatus('')
        if (allAppointmentsData) {
            setAppointmentsList(allAppointmentsData.data);
        }
    };
    const navigate = useNavigate();
    const itemsPerPageOptions = [10, 20, 30, 50, 100];
    const totalItems = appointmentsList.length;
    const totalPages = Math.ceil(totalItems / selectedItemsPerPage);
    useEffect(() => {
        if (appointmentsList.length > 0) {
            let sortedData = [...appointmentsList];
            if (sortBy) {
                sortedData.sort((a, b) => {
                    const aValue = (a[sortBy.key] || '') as string;
                    const bValue = (b[sortBy.key] || '') as string;
                    const result = sortBy && sortBy.ascending ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
                    return result || 0; // Provide a fallback value of 0 if result is undefined
                });
            }
            setVisibleData(
                sortedData.slice(
                    (currentPage - 1) * selectedItemsPerPage,
                    currentPage * selectedItemsPerPage
                )
            );
        }
    }, [appointmentsList, currentPage, selectedItemsPerPage, sortBy]);
    // Search For Appointment
    const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        const searchTerm = e.currentTarget.value;
        setSearchInput(searchTerm);
        const filteredItems = allAppointmentsData?.data.filter((appointment) => {
            return appointment.people_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                appointment.full_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                appointment.email?.toLowerCase().includes(searchTerm.toLowerCase()
                );
        });
        setAppointmentsList(filteredItems);
    };
    // Delete Appointments
    const handleDeleteAppointment = useMutation((id: number) => {
        return delete_appointment(id)
            .then((response) => {
                toast.success(response.message, {
                    position: 'top-right',
                    autoClose: 3000,
                })
                refetch();
            })
            .catch((error) => {
                if (error.response) {
                    const errorMessage = error.response.data.message
                    toast.error(errorMessage, {
                        position: 'top-right',
                        autoClose: 2000,
                    });
                };
            });
    });
    const handleDeleteAppointmentNote = useMutation((id: number) => {
        return delete_appointment_note(id)
            .then((response) => {
                toast.success(response.message, {
                    position: 'top-right',
                    autoClose: 3000,
                })
                refetchAppointemntNotes();
            })
            .catch((error) => {
                if (error.response) {
                    const errorMessage = error.response.data.message
                    toast.error(errorMessage, {
                        position: 'top-right',
                        autoClose: 2000,
                    });
                };
            });
    });
    const handleNoteChange = (id: number, newNote: string) => {
        setAppointmentsNotesList(prevNotes =>
            prevNotes.map(note =>
                note.id === id ? { ...note, appointment_notes: newNote } : note
            )
        );
    };
    const handleAddAppointmentNotes = useMutation((appointmentNotesData: { appointmentId: number; appointmentNotes: string }) => {
        return add_appointemnt_note(appointmentNotesData.appointmentId, appointmentNotesData.appointmentNotes)
            .then((data) => {
                toast.success(data.message, {
                    position: 'top-right',
                    autoClose: 3000,
                })
                refetchAppointemntNotes();
                setnewAppointmentNote('')
            })
            .catch((error) => {
                toast.error(error.response.data.message, {
                    position: 'top-right',
                    autoClose: 3000,
                })
            });
    });
    const handleUpdateAppointmentNotes = useMutation((updatedAppointmentNotesData: { id: number; appointmentId: number; appointmentNotes: string }) => {
        return update_appointemnt_note(updatedAppointmentNotesData.id, updatedAppointmentNotesData.appointmentId, updatedAppointmentNotesData.appointmentNotes)
            .then((data) => {
                toast.success(data.message, {
                    position: 'top-right',
                    autoClose: 3000,
                })
                refetch();
            })
            .catch((error) => {
                toast.error(error.response.data.message, {
                    position: 'top-right',
                    autoClose: 3000,
                })
            });
    });

    const handleStatusChange = useMutation(({ id, status }: { id: number, status: string | undefined }) => {
        let newStatus;
        if (status == 'open') {
            newStatus = 'close'
        } else if (status == 'close') {
            newStatus = 'open'
        }
        return change_appointment_status(id, newStatus)
            .then((response) => {
                toast.success(response.message, {
                    position: 'top-right',
                    autoClose: 3000,
                });
            })
            .then(() => {
                return get_all_appointments().then((data) => {
                    setAppointmentsList(data.data);
                });
            })
            .catch((error: any) => {
                if (error.response) {
                    const errorMessage = error.response.data.message;
                    toast.error(errorMessage, {
                        position: 'top-right',
                        autoClose: 2000,
                    });
                }
            });
    });
    const handleGetNotes = (appointment) => {
        setAppointmentId(appointment.id);
        setSelectedAppointment({ ...appointment });
    }
    const handleDateFromChange = (date) => {
        if (date && date[0]) {
            const selectedDate = new Date(date[0]);
            selectedDate.setHours(0, 0, 0, 0);
            const formattedDate = formatDateFlatpickr(selectedDate);
            setFromDate(formattedDate);

            // Update the "To Date" Flatpickr options
            if (toDateRef.current) {
                toDateRef.current.flatpickr.set('minDate', selectedDate);
            }
        } else {
            setFromDate('');
            // Reset the "To Date" picker minDate when "From Date" is cleared
            if (toDateRef.current) {
                toDateRef.current.flatpickr.set('minDate', null);
            }
        }
    };
    const handleDateToChange = (date) => {
        if (date && date[0]) {
            const selectedDate = new Date(date[0]);
            selectedDate.setHours(0, 0, 0, 0);
            const formattedDate = formatDateFlatpickr(selectedDate);
            setToDate(formattedDate);
        } else {
            setToDate('');
        }
    };
    const handleSort = (key: string) => {
        if (!sortBy || sortBy.key !== key || sortBy.ascending) {
            setSortBy({ key, ascending: false }); // Sort in descending order initially
        } else if (key === "people_name") {
            setSortBy({ key, ascending: !sortBy.ascending });
        } else if (key === "email") {
            setSortBy({ key, ascending: !sortBy.ascending });
        } else if (key === "appointment_date_time") {
            setSortBy({ key, ascending: !sortBy.ascending });
        } else if (key === "updated_at") {
            setSortBy({ key, ascending: true });
        }
        else {
            setSortBy(null);
        }
    };
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };
    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
    // Get Single Appointment Logs
    const handleAppointmentLogs = (appointment) => {
        navigate("/appointments/appointment-logs", { state: { id: appointment.id } });
    };
    //edit Appointment
    const handleAppointmentEdit = (appointment) => {
        // navigate("/people/edit-person", { state: { id: appointment.id } });
    };
    // view Appointment
    const handleAppointmentView = (appointment) => {
        navigate("/appointments/view-appointment", { state: { id: appointment.id } });
    };
    return (
        <div className='card card-xxl-stretch mx-4 mb-5 mb-xl-10'>
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bolder fs-3 mb-1'>List of Appointments</span>
                </h3>
                {/* commenting for future use  */}
                {/* <div
                    className='card-toolbar'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Click to add a Person'
                >
                    <button
                        data-toggle="tooltip" data-placement="bottom" title="Add Person"
                        className='btn btn-sm btn-light-dark'
                        onClick={() => navigate('/people/add-person')}
                    >
                        <KTIcon iconName='plus' className='fs-3' />
                        Add Person
                    </button>
                </div> */}
            </div>
            <div className='card-header border-0 pt-5 d-flex justify-content-between align-items-center'>
                <h3 className='card-title align-items-start flex-column'>
                    <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                    <input
                        data-toggle="tooltip" data-placement="bottom" title="Search Appointments"
                        type='text'
                        data-kt-user-table-filter='search'
                        className='form-control form-control-solid w-250px ps-14'
                        placeholder='Search Appointments'
                        value={searchInput}
                        onChange={handleInputChange}
                    />
                </h3>
                <div className='card-toolbar d-flex align-items-center'>
                    <div className='mx-4'>
                        <select
                            className='form-select form-select-solid form-select-lg fw-bold'
                            value={paymentStatus}
                            onChange={(e) => setPaymentStatus(e.target.value)}
                        >
                            <option value=''>Filter by Payment Status...</option>
                            <option value="pending">Pending</option>
                            <option value="waiting payment">Waiting Payment</option>
                            <option value="Accepted">Accepted</option>
                        </select>
                    </div>
                    <div className='mx-4'>
                        <select
                            name='type'
                            className='form-select form-select-solid form-select-lg fw-bold'
                            value={appointmentStatus}
                            onChange={(e) => setAppointmentStatus(e.target.value)}
                        >
                            <option value=''>Filter by Appointment Status...</option>
                            <option value='open'>Open</option>
                            <option value='close'>Close</option>
                            <option value='rejected'>Rejected</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className='card-header border-0 pt-5 mx-3 d-flex flex-column align-items-end'>
                <div className='card-toolbar d-flex align-items-center'>
                    <div>
                        <select
                            className='form-select form-select-solid form-select-lg fw-bold'
                            value={peopleId}
                            onChange={(e) => setPeopleId(e.target.value)}
                        >
                            <option value=''>Select a Person...</option>
                            {Array.isArray(peopleList) && peopleList.length > 0 ? (
                                peopleList.map((person) => (
                                    <option key={person.id} value={person.id}>
                                        {person.first_name + " " + person.last_name}
                                    </option>
                                ))
                            ) : (
                                <option value='' disabled>No People available</option>
                            )}
                        </select>
                    </div>
                    <div className='mx-3'>
                        <Flatpickr
                            ref={fromDateRef}
                            onChange={handleDateFromChange}
                            className='form-control form-control-solid'
                            placeholder='From Date'
                            options={{
                                dateFormat: 'd/m/Y',
                            }}
                        />
                    </div>
                    <div>
                        <Flatpickr
                            ref={toDateRef}
                            onChange={handleDateToChange}
                            className='form-control form-control-solid'
                            placeholder='To Date'
                            options={{
                                dateFormat: 'd/m/Y',
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className='card-header border-0 pt-5 mx-3 d-flex flex-column align-items-end'>
                <div>
                    <button
                        className='btn btn-primary me-3'
                        onClick={handleApplyFilters}
                    >
                        Apply Filters
                    </button>
                    <button
                        className='btn btn-light-danger'
                        onClick={handleClearFilters}
                    >
                        Clear Filters
                    </button>
                </div>
            </div>
            <div className='card-body py-3'>
                <div className='table-responsive'>
                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                        {/* Headings of columns in table  */}
                        <thead>
                            <tr className='fw-bold text-muted'>
                                <th className='min-w-200px'>Appointment With  <span onClick={() => handleSort('people_name')}>
                                    <KTIcon iconName='up-down' className='fs-4 cursor-pointer' />
                                </span></th>
                                <th className='min-w-200px'>Appointment By <span onClick={() => handleSort('full_name')}>
                                    <KTIcon iconName='up-down' className='fs-4 cursor-pointer' />
                                </span></th>
                                <th className='min-w-140px'>Customer Email <span onClick={() => handleSort('email')}>
                                    <KTIcon iconName='up-down' className='fs-4 cursor-pointer' />
                                </span></th>
                                <th className='min-w-200px'>Date/Time  <span onClick={() => handleSort('appointment_date_time')}>
                                    <KTIcon iconName='up-down' className='fs-4 cursor-pointer' />
                                </span></th>
                                <th className='min-w-200px'>Payment Status  <span onClick={() => handleSort('payment_status')}>
                                    <KTIcon iconName='up-down' className='fs-4 cursor-pointer' />
                                </span></th>
                                <th className='min-w-100px'>Status <span onClick={() => handleSort('appointment_status')}>
                                    <KTIcon iconName='up-down' className='fs-4 cursor-pointer' />
                                </span></th>
                                <th className='min-w-150px'>Updated At  <span onClick={() => handleSort('updated_at')}>
                                    <KTIcon iconName='up-down' className='fs-4 cursor-pointer' />
                                </span></th>
                                {appointment_activity_log || appointment_delete || appointment_edit || appointment_view ? (
                                    <th className='min-w-150px'>Actions</th>
                                ) : null}
                            </tr>
                        </thead>
                        <tbody>
                            {isAllAppointmentsError ? (
                                <tr>
                                    <td>Error loading data. Please try again later.</td>
                                </tr>
                            ) : isAllAppointmentsLoading ? (
                                <tr>
                                    <td colSpan={6}>
                                        <div className='text-center'>
                                            <div className='spinner-border' role='status'>
                                                <span className='visually-hidden'>Loading...</span>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ) : appointmentsList.length > 0 ? (
                                visibleData.map((appointment) => (
                                    <tr key={appointment.id}>
                                        <td>
                                            <div className='d-flex align-items-center'>
                                                <div className='d-flex justify-content-start flex-column'>
                                                    <h3 className='text-dark fw-bold cursor-pointer fs-6'>
                                                        {appointment.people_name}
                                                    </h3>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex align-items-center'>
                                                <div className='d-flex justify-content-start flex-column'>
                                                    <h3 className='text-dark fw-bold cursor-pointer fs-6'>
                                                        {appointment.full_name}
                                                    </h3>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex align-items-center'>
                                                <div className='d-flex justify-content-start flex-column'>
                                                    <h3 className='text-dark fw-bold cursor-pointer fs-6'>
                                                        {appointment.email}
                                                    </h3>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex align-items-center'>
                                                <div className='d-flex justify-content-start flex-column'>
                                                    <h3 className='text-dark fw-bold cursor-pointer fs-6'>
                                                        {appointment.appointment_date_time_slots?.[0]?.datetime
                                                            ? formatDateWithTime(appointment.appointment_date_time_slots[0].datetime)
                                                            : 'No Date Available'}
                                                    </h3>
                                                </div>
                                            </div>

                                        </td>
                                        <td>
                                            <div className='d-flex align-items-center'>
                                                <div className='d-flex justify-content-start flex-column'>
                                                    <h3 className='text-dark fw-bold cursor-pointer fs-6'>
                                                        {appointment.payment_status
                                                            ? appointment.payment_status
                                                            : '-'}
                                                    </h3>
                                                </div>
                                            </div>

                                        </td>
                                        <td>{appointment.appointment_status == 'open' ? (
                                            <div className='d-flex align-items-center'>
                                                <div className='d-flex justify-content-start flex-column'>
                                                    <h3 className='fw-bold cursor-pointer fs-6 badge badge-success'>
                                                        {appointment.appointment_status}
                                                    </h3>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className='d-flex align-items-center'>
                                                <div className='d-flex justify-content-start flex-column'>
                                                    <h3 className='fw-bold cursor-pointer fs-6 badge badge-warning'>{appointment.appointment_status}</h3>
                                                </div>
                                            </div>
                                        )}
                                        </td>
                                        <td>
                                            <div className='d-flex align-items-center'>
                                                <div className='d-flex justify-content-start cursor-pointer'>
                                                    {formatDateWithTime(appointment.updated_at)}
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex justify-content-end flex-shrink-0'>
                                                {appointment.appointment_status == 'open' ? (
                                                    <button data-toggle="tooltip" data-placement="bottom" title="Change Status" type="button" className="btn btn-sm btn-success text-sm py-0 px-2 mx-1 fs-9"
                                                        onClick={() => {
                                                            handleStatusChange.mutate({ id: appointment.id, status: appointment.appointment_status });
                                                        }}>
                                                        Change Status
                                                    </button>
                                                ) : null}
                                                {appointment_edit && (
                                                    <>
                                                        <button
                                                            data-toggle="tooltip" data-placement="bottom" title="Edit Appointment"
                                                            className='btn btn-icon btn-light-dark btn-active-color-warning btn-sm me-1'
                                                            onClick={() => {
                                                                handleAppointmentEdit(appointment);
                                                            }}
                                                        >
                                                            <KTIcon iconName='pencil' className='fs-3' />
                                                        </button>
                                                    </>
                                                )}
                                                <button
                                                    data-toggle="tooltip" data-placement="bottom" title="Appointment Notes"
                                                    className='btn btn-icon btn-light-dark btn-active-color-primary btn-sm me-1'
                                                    data-bs-toggle='modal'
                                                    data-bs-target='#kt_modal_2'
                                                    onClick={() => {
                                                        handleGetNotes(appointment)
                                                    }}
                                                >
                                                    <KTIcon iconName='file' className='fs-3' />
                                                </button>
                                                {appointment_view && (
                                                    <button
                                                        data-toggle="tooltip" data-placement="bottom" title="See Appointment Detail"
                                                        onClick={() => handleAppointmentView(appointment)}
                                                        className='btn btn-icon btn-light-dark btn-active-color-warning btn-sm me-1'
                                                    >
                                                        <KTIcon iconName='eye' className='fs-3' />
                                                    </button>
                                                )}
                                                {appointment_activity_log && (
                                                    <button
                                                        onClick={() => {
                                                            handleAppointmentLogs(appointment);
                                                        }}
                                                        className='btn btn-icon btn-light-dark btn-active-color-warning btn-sm me-1'
                                                        data-bs-toggle='tooltip'
                                                        data-bs-placement='top'
                                                        data-bs-custom-class='tooltip-dark'
                                                        title='Appointment Logs'
                                                    >
                                                        <i className='bi bi-journal-text'></i>
                                                    </button>
                                                )}
                                                {appointment_delete && (
                                                    <button
                                                        data-toggle="tooltip" data-placement="bottom" title="Delete Appointment"
                                                        className='btn btn-icon btn-light-dark btn-active-color-danger btn-sm'
                                                        data-bs-toggle='modal'
                                                        data-bs-target='#kt_modal_1'
                                                        onClick={() => {
                                                            setSelectedAppointment({ ...appointment })
                                                        }}
                                                    >
                                                        <KTIcon iconName='trash' className='fs-3' />
                                                    </button>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td>No Appointments Available.</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Delete modal */}
            <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
                <div className='modal-dialog'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>Delete Appointment Confirmation</h5>
                            <div
                                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                                data-bs-dismiss='modal'
                                aria-label='Close'
                            >
                                <KTSVG
                                    path='/media/icons/duotune/arrows/arr061.svg'
                                    className='svg-icon svg-icon-2x'
                                />
                            </div>
                        </div>
                        <div className='modal-body'>
                            {selectedAppointment ? (
                                <>
                                    <p className='mb-2'>
                                        <span className='fw-bold p-3'>Appointment With</span>{selectedAppointment.people_name}
                                    </p>
                                    <p className='mb-2'>
                                        <span className='fw-bold p-3'>Appointment By</span>{selectedAppointment.full_name}
                                    </p>
                                    <p className='mb-2'>
                                        <span className='fw-bold p-3'>Date & Time of Appointment</span> {selectedAppointment.appointment_date_time_slots?.[0]?.datetime
                                                            ? formatDateWithTime(selectedAppointment.appointment_date_time_slots[0].datetime)
                                                            : 'No Date Available'}
                                    </p>
                                </>
                            ) : null}
                        </div>
                        <div className='modal-footer'>
                            <button data-toggle="tooltip" data-placement="bottom" title="Close" type='button' className='btn btn-light' data-bs-dismiss='modal'>
                                Close
                            </button>
                            <button
                                data-toggle="tooltip" data-placement="bottom" title="Delete this Appointment"
                                type='button'
                                className='btn btn-danger'
                                data-bs-dismiss='modal'
                                onClick={() => {
                                    if (selectedAppointment) {
                                        handleDeleteAppointment.mutate(selectedAppointment.id)
                                    }
                                }}
                            >
                                Delete Appointment
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* notes modal  */}
            <div className='modal fade' tabIndex={-1} id='kt_modal_2'>
                <div className='modal-dialog'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>Appointment Notes</h5>
                            <div
                                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                                data-bs-dismiss='modal'
                                aria-label='Close'
                            >
                                <KTSVG
                                    path='/media/icons/duotune/arrows/arr061.svg'
                                    className='svg-icon svg-icon-2x'
                                />
                            </div>

                        </div>
                        <div className='modal-body'>
                            <>
                                <div className='row mb-6'>
                                    <label className='col-lg-12 col-form-label fw-bold fs-6'>Add Note</label>
                                    <div className='col-lg-12 fv-row'>
                                        <textarea
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Add Notes....'
                                            value={newAppointmentNote}
                                            onChange={(e) => setnewAppointmentNote(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className='d-flex justify-content-end'>
                                    <button
                                        data-toggle="tooltip" data-placement="bottom" title="Delete this Appointment"
                                        type='button'
                                        className='btn btn-primary'
                                        onClick={() => {
                                            if (selectedAppointment && selectedAppointment.id && newAppointmentNote) {
                                                handleAddAppointmentNotes.mutateAsync({
                                                    appointmentId: selectedAppointment.id,
                                                    appointmentNotes: newAppointmentNote,
                                                })
                                            }
                                        }}
                                    >
                                        Add Appointment Note
                                    </button>
                                </div>
                                <hr />
                                {appointmentsNotesList.length > 0 ? (
                                    <>

                                        <h3>List of Appointment Notes</h3>
                                        <div style={{
                                            maxHeight: appointmentsNotesList.length > 2 ? '300px' : 'auto',
                                            overflowY: appointmentsNotesList.length > 2 ? 'auto' : 'visible',
                                        }}>
                                            {appointmentsNotesList.map((appointmentNote) => (
                                                <>
                                                    <div className='row mb-6' key={appointmentNote.id}>
                                                        <label className='col-lg-12 col-form-label fw-bold fs-6'>Note</label>
                                                        <div className='col-lg-9 fv-row'>
                                                            <textarea
                                                                className='form-control form-control-lg form-control-solid'
                                                                placeholder='Add Notes....'
                                                                value={appointmentNote.appointment_notes}
                                                                onChange={(e) => handleNoteChange(appointmentNote.id, e.target.value)}
                                                            />
                                                        </div>
                                                        <div className='col-lg-3 fv-row'>
                                                            <button
                                                                data-toggle="tooltip" data-placement="bottom" title="Save Appointment Note"
                                                                className='btn btn-icon btn-light-success btn-active-color-dark-success btn-sm me-1 mt-2'
                                                                onClick={() => {
                                                                    if (appointmentNote && appointmentNote.appointment_id && appointmentNote.appointment_notes) {
                                                                        handleUpdateAppointmentNotes.mutateAsync({
                                                                            id: appointmentNote.id,
                                                                            appointmentId: appointmentNote.appointment_id,
                                                                            appointmentNotes: appointmentNote.appointment_notes,
                                                                        })
                                                                    }
                                                                }}
                                                            >
                                                                <KTIcon iconName='check' className='fs-3' />
                                                            </button>
                                                            <button
                                                                data-toggle="tooltip" data-placement="bottom" title="Delete Appointment Note"
                                                                className='btn btn-icon btn-light-danger btn-active-color-dark-danger btn-sm me-1 mt-2'
                                                                onClick={() => {
                                                                    if (appointmentNote) {
                                                                        handleDeleteAppointmentNote.mutate(appointmentNote.id)
                                                                    }
                                                                }}
                                                            >
                                                                <KTIcon iconName='trash' className='fs-3' />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </>
                                            ))}

                                        </div>
                                    </>) : null}
                            </>
                        </div>
                    </div>
                </div>
            </div>
            <div className='card-footer d-flex justify-content-between py-4'>
                <div className='align-items-center row'>
                    <div className='d-flex'>
                        <div className='col-md-9 mt-5'><strong>Results Per Page:</strong></div>
                        <select
                            className='form-select-md'
                            value={selectedItemsPerPage}
                            onChange={(e) => setSelectedItemsPerPage(Number(e.target.value))}
                        >
                            {itemsPerPageOptions.map(option => (
                                <option key={option} value={option} className='p-3 '>{option}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className='d-flex justify-content-end'>
                    {currentPage === 1 ? (
                        <button
                            className='btn btn-light'
                            onClick={handlePreviousPage}
                            disabled={currentPage === 1}
                        >
                            Previous
                        </button>
                    ) : (
                        <button
                            className='btn btn-primary'
                            onClick={handlePreviousPage}
                            disabled={currentPage === 1}
                        >
                            Previous
                        </button>
                    )}
                    <button
                        className='btn btn-primary ms-2'
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    )
}

export default AppointmentsList