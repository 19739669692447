// Interface Add Category
export interface addCategories {
  categoryName: string
  status:number,
};
export const initialValues: addCategories = {
  categoryName: '',
  status:1,
};
//Interface for subcategories
export interface addSubcategories {
  categoryTitle: string
  sTitle: string
  status: number
};
export const addSubcategoriesInitValues: addSubcategories = {
  categoryTitle: '',
  sTitle: '',
  status: 1
};

// Interface Add Location
export interface addLocations{
  platform_name:string,
  type: string,
  status:number,
}
export const initialValuesLocations: addLocations = {
  type:"",
  platform_name:"",
  status:1,
};
// Interface Add Location
export interface addPerson{
  title:string,
  first_name: string,
  middle_name: string,
  last_name: string,
  address_line_1: string,
  address_line_2: string,
  town: string,
  city: string,
  postal_code: string,
  country: string,
  mobile_number: string,
  phone_number: string,
  picture: string,
  other_information: string,
  email: string,
  password: string,
  confirm_password: string,
  appointment_rate: string,
  status:number,
  sub_categories: { value: string }[]
  people_locations: { value: string }[]
  people_platforms: { value: string }[]
}
export const initialValuesPerson: addPerson = {
  title:"",
  first_name: "",
  middle_name: "",
  last_name: "",
  address_line_1: "",
  address_line_2: "",
  town: "",
  city: "",
  postal_code: "",
  country: "",
  mobile_number: "",
  phone_number: "",
  picture: "",
  other_information: "",
  email: "",
  password: "",
  confirm_password: "",
  appointment_rate: "",
  status: 1,
  sub_categories: [],
  people_locations: [],
  people_platforms: [],
};
export interface IProfileDetails {
  avatar: string
  fName: string
  lName: string
  company: string
  contactPhone: string
  companySite: string
  country: string
  language: string
  timeZone: string
  currency: string
  communications: {
    email: boolean
    phone: boolean
  }
  allowMarketing: boolean
}
// Users Interface
export interface addUser {
  title: string;
  firstname: string;
  role: string;
  middlename: string;
  lastname: string;
  telephone: string;
  address: string;
  email: string;
  password: string;
  mobile: string;
  picture: string;
  status:number,
}
// list of Role Type
export interface Roles {
  id: number
  name: string
  created_at: string
  updated_at: string
};
// Add Roles TS Type
export type AddRoles = {
  name: string
  status: number
  organization_id:number
  permissions: string[]
};
//List Permissions of Add Role
export interface Permission {
  permission_key: string
  title: string
  create: string
  view: string
  update: string
  delete: string
  "activity-log" : string
  guard_name: string
};
// Use for Edit
export interface EditRoleType extends Permission {
  id: number;
  name: string;
  status: number;
  permissions: string[];
};
export interface IUpdateEmail {
  newEmail: string
  confirmPassword: string
}

export interface IUpdatePassword {
  currentPassword: string
  newPassword: string
  passwordConfirmation: string
}
export interface AccountPassword {
  password: string
  newPassword: string
  passwordConfirmation: string
}

export interface IConnectedAccounts {
  google: boolean
  github: boolean
  stack: boolean
}
export interface UpdatePasswordTypes  {
  password: string
  new_password: string
  confirm_password: string
}
export const updatePasswordUser: UpdatePasswordTypes = {
  password: '',
  new_password: '',
  confirm_password: '',
}

export interface IEmailPreferences {
  successfulPayments: boolean
  payouts: boolean
  freeCollections: boolean
  customerPaymentDispute: boolean
  refundAlert: boolean
  invoicePayments: boolean
  webhookAPIEndpoints: boolean
}

export interface INotifications {
  notifications: {
    email: boolean
    phone: boolean
  }
  billingUpdates: {
    email: boolean
    phone: boolean
  }
  newTeamMembers: {
    email: boolean
    phone: boolean
  }
  completeProjects: {
    email: boolean
    phone: boolean
  }
  newsletters: {
    email: boolean
    phone: boolean
  }
}

export interface IDeactivateAccount {
  confirm: boolean
}

export const profileDetailsInitValues: IProfileDetails = {
  avatar: '/media/avatars/300-1.jpg',
  fName: 'Max',
  lName: 'Smith',
  company: 'Keenthemes',
  contactPhone: '044 3276 454 935',
  companySite: 'keenthemes.com',
  country: '',
  language: '',
  timeZone: '',
  currency: '',
  communications: {
    email: false,
    phone: false,
  },
  allowMarketing: false,
}

export const updateEmail: IUpdateEmail = {
  newEmail: 'support@keenthemes.com',
  confirmPassword: '',
}

export const updatePassword: IUpdatePassword = {
  currentPassword: '',
  newPassword: '',
  passwordConfirmation: '',
}

export const connectedAccounts: IConnectedAccounts = {
  google: true,
  github: true,
  stack: false,
}

export const emailPreferences: IEmailPreferences = {
  successfulPayments: false,
  payouts: true,
  freeCollections: false,
  customerPaymentDispute: true,
  refundAlert: false,
  invoicePayments: true,
  webhookAPIEndpoints: false,
}

export const notifications: INotifications = {
  notifications: {
    email: true,
    phone: true,
  },
  billingUpdates: {
    email: true,
    phone: true,
  },
  newTeamMembers: {
    email: true,
    phone: false,
  },
  completeProjects: {
    email: false,
    phone: true,
  },
  newsletters: {
    email: false,
    phone: false,
  },
}

export const deactivateAccount: IDeactivateAccount = {
  confirm: false,
}
// get location list type
export interface allLocationType{
  id:number
  type: string,
  name:string,
  platform_name:string,
  updated_at:string,
  updated_by:string,
  status:number,
}
// get location list type
export interface allPeople{
  id:number
  title:string,
  selected_category_ids:{
    id:number,
    title:string
}[],
  first_name: string,
  middle_name: string,
  last_name: string,
  address_line_1: string,
  address_line_2: string,
  town: string,
  city: string,
  postal_code: string,
  country: string,
  mobile_number: string,
  phone_number: string,
  picture: string | undefined,
  other_information: string,
  email: string,
  password: string,
  confirm_password: string,
  appointment_rate: string,
  sub_categories: { 
    id: number,
    people_id: number;
    people_title: string,
    sub_category_id: number,
    sub_category_title: string }[]
  people_locations: { 
    id: number,
    people_id: number;
    people_title: string,
    location_id: number,
    type: string,
    platform_name: string}[]
  people_platforms: { 
    id: number,
    people_id: number;
    people_title: string,
    location_id: number,
    type: string,
    platform_name: string}[]
  updated_at:string,
  updated_by:string,
  status:number,
}
// For General Settings
export interface addGeneralSettings{
  category_title:string,
  subcategory_title: string,
  theme:string,
}
export const initialValuesGeneralSettings:addGeneralSettings = {
  category_title:"",
  subcategory_title:"",
  theme:"light",
};
// For add on Settings
export interface addOnSettings{
  setting_value:string,
}
export const initialValuesAddOnSettings:addOnSettings = {
  setting_value:"",
};
// Add Organizations Interface
export interface addOrganization {
  organisation_name: string;
  address_line_1: string;
  address_line_2: string;
  country: string;
  city: string;
  post_code: string;
  phone_1: string;
  phone_2: string;
  email: string;
  website: string;
  currency_name: string;
  currency_symbol: string;
  favicon: File | null;
  white_logo: File | null;
  colored_logo: File | null;
}
export const addOragnizationInitValues: addOrganization = {
  organisation_name: "",
  address_line_1: "",
  address_line_2: "",
  country: "",
  city: "",
  post_code: "",
  phone_1: "",
  phone_2: "",
  email: "",
  website: "",
  currency_name: "",
  currency_symbol: "",
  favicon: null,
  white_logo: null,
  colored_logo: null
};
export const country= [
  "Afghanistan",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegowina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo",
  "Congo, the Democratic Republic of the",
  "Cook Islands",
  "Costa Rica",
  "Cote d'Ivoire",
  "Croatia (Hrvatska)",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "East Timor",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands (Malvinas)",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "France Metropolitan",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard and Mc Donald Islands",
  "Holy See (Vatican City State)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran (Islamic Republic of)",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea, Democratic People's Republic of",
  "Korea, Republic of",
  "Kuwait",
  "Kyrgyzstan",
  "Lao, People's Democratic Republic",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libyan Arab Jamahiriya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macau",
  "Macedonia, The Former Yugoslav Republic of",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia, Federated States of",
  "Moldova, Republic of",
  "Monaco", "Mongolia",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "Netherlands Antilles",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Reunion",
  "Romania",
  "Russian Federation",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia (Slovak Republic)",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "Spain",
  "Sri Lanka",
  "St. Helena",
  "St. Pierre and Miquelon",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen Islands",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan, Province of China",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "United States Minor Outlying Islands",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Vietnam",
  "Virgin Islands (British)",
  "Virgin Islands (U.S.)",
  "Wallis and Futuna Islands",
  "Western Sahara",
  "Yemen",
  "Yugoslavia",
  "Zambia",
  "Zimbabwe"
]