import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { KTIcon, KTSVG } from '../../../../_metronic/helpers';
import { toast } from 'react-toastify';
import { allPeople } from '../../../modules/accounts/components/settings/SettingsModel';
import { formatDateWithTime } from '../../../helper-functions/DateFunction';
import { change_active_status_people, delete_people, get_all_filtered_people, get_all_people } from '../api/peopleApi';
import { get_all_active_subcategories } from '../../subcategories/api/subcategoriesApi';
import { permissionCheck } from '../../../helper-functions/PermissionCheck';
interface Subcategory {
  id: number;
  title: string;
  categories_id: number | undefined;
  categories_title: string | undefined;
};
var people_create, people_edit, people_delete, people_activity_log, people_view
function PeopleList() {
  const [searchInput, setSearchInput] = useState('');
  const [selectedPerson, setSelectedPerson] = useState<allPeople | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedItemsPerPage, setSelectedItemsPerPage] = useState(10); // Step 1: State variable for items per page
  const [sortBy, setSortBy] = useState<{ key: string, ascending: boolean } | null>(null); // State variable for sorting
  const [subcategories, setSubcategories] = useState<Subcategory[]>([])
  const [visibleData, setVisibleData] = useState<allPeople[]>([]);
  const [peopleList, setPeopleList] = useState<allPeople[]>([]);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedSubcategory, setSelectedSubcategory] = useState('');
  people_create = permissionCheck('people-create')
  people_edit = permissionCheck('people-update')
  people_delete = permissionCheck('people-delete')
  people_activity_log = permissionCheck('people-activity-log')
  people_view = permissionCheck('people-view')
  const { data: allPeople, isLoading: isAllPeopleLoading, isError: isAllPeopleError, refetch } = useQuery('people', get_all_people, {refetchOnWindowFocus: false});
  useEffect(() => {
    if (allPeople) {
      setPeopleList(allPeople.data)
    }
  }, [allPeople]);
  const { data: subcategoryData } = useQuery('subcategory', get_all_active_subcategories, {refetchOnWindowFocus: false});
  useEffect(() => {
    if (subcategoryData) {
      setSubcategories(subcategoryData.data as Subcategory[])
    }
  }, [subcategoryData]);
  // Handle Filter People
  const { data: filterData, refetch: filterRefetchPeople } = useQuery(
    'filterData',
    () => get_all_filtered_people(selectedSubcategory, selectedStatus),
    {
      refetchOnWindowFocus: false,
      onSuccess: (filteredData) => {
        setPeopleList(filteredData.data);
      },
      onError: (error: any) => {
        // Error handling
        if (error.response) {
          const errorMessage = error.response.data.message;
          toast.error(errorMessage, {
            position: 'top-right',
            autoClose: 2000,
          });
        }
      },
      enabled: false, // Set initial enable state to false
    }
  );
  const handleApplyFilters = () => {
    filterRefetchPeople({ // Enable the query before refetch
      throwOnError: true,
    });
  };
  const handleClearFilters = () => {
    setSelectedSubcategory('');
    setSelectedStatus('');
    // Reset to the original people list
    if (allPeople) {
      setPeopleList(allPeople.data)
    }
  };
  const navigate = useNavigate();
  const itemsPerPageOptions = [10, 20, 30, 50, 100]; // Step 2: Options for items per page
  const totalItems = peopleList.length;
  const totalPages = Math.ceil(totalItems / selectedItemsPerPage); // Update total pages based on selected items per page
  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    };
  };
  useEffect(() => {
    if (peopleList.length > 0) {
      let sortedData = [...peopleList];
      if (sortBy) {
        sortedData.sort((a, b) => {
          if (sortBy.key === 'status' && typeof a.status === 'number' && typeof b.status === 'number') {
            return sortBy.ascending ? a.status - b.status : b.status - a.status;
          } else {
            const aValue = (a[sortBy.key] || '') as string;
            const bValue = (b[sortBy.key] || '') as string;
            return sortBy && sortBy.ascending ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
          }
        });
      }
      setVisibleData(
        sortedData.slice(
          (currentPage - 1) * selectedItemsPerPage,
          currentPage * selectedItemsPerPage
        )
      );
    }
  }, [peopleList, currentPage, selectedItemsPerPage, sortBy]);
  // Search For people
  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    const searchTerm = e.currentTarget.value;
    setSearchInput(searchTerm);
    const filteredItems = allPeople.data.filter((people) => {
      return people.first_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        people.last_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        people.email?.toLowerCase().includes(searchTerm.toLowerCase()
        );
    });
    setPeopleList(filteredItems);
  };
  // Delete Person
  const handleDeletePerson = useMutation((id: number) => {
    return delete_people(id)
      .then((response) => {
        toast.success(response.message, {
          position: 'top-right',
          autoClose: 3000,
        })
        refetch();
      })
      .catch((error) => {
if (error.response) {
          const errorMessage = error.response.data.message;
          // Show error toast with HTML content
          toast.error(
            <div dangerouslySetInnerHTML={{ __html: errorMessage }} />,
            {
              position: 'top-right',
              autoClose: 6000,
            }
          );
        }
      });
  });
  // Status Update People
  const handleStatusUpdate = useMutation((id: number) => {
    return change_active_status_people(id)
      .then((response) => {
        toast.success(response.message, {
          position: 'top-right',
          autoClose: 3000,
        });
        refetch();
      })
      .catch((error: any) => {
        if (error.response) {
          const errorMessage = error.response.data.message;
          // Show error toast with HTML content
          toast.error(
            <div dangerouslySetInnerHTML={{ __html: errorMessage }} />,
            {
              position: 'top-right',
              autoClose: 6000,
            }
          );
        }
      });
  });
  const handleSort = (key: string) => {
    if (!sortBy || sortBy.key !== key || sortBy.ascending) {
      setSortBy({ key, ascending: false }); // Sort in descending order initially
    } else if (key === "first_name") {
      setSortBy({ key, ascending: !sortBy.ascending });
    } else {
      // Toggle between ascending and descending order
      setSortBy({ key, ascending: !sortBy.ascending });
    }
  };
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  // Get Single Person Logs
  const handlePeopleLogs = (person) => {
    navigate("/people/person-logs", { state: { id: person.id } });
  };
  //edit people
  const handlePeopleEdit = (person) => {
    navigate("/people/edit-person", { state: { id: person.id } });
  };
  // view people
  const handleViewPeople = (person) => {
    navigate("/people/view-person", { state: { id: person.id } });
  };
  return (
    <div className='card card-xxl-stretch mx-4 mb-5 mb-xl-10'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>List of People</span>
        </h3>
        {people_create && (
          <div
            className='card-toolbar'
          >
            <button
              data-toggle="tooltip" data-placement="bottom" title="Add Person"
              className='btn btn-sm btn-light-dark'
              onClick={() => navigate('/people/add-person')}
            >
              <KTIcon iconName='plus' className='fs-3' />
              Add Person
            </button>
          </div>
        )}
      </div>
      <div className='card-header border-0 pt-5 d-flex justify-content-between align-items-center'>
        <h3 className='card-title align-items-start flex-column'>
          <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
          <input
            data-toggle="tooltip" data-placement="bottom" title="Search People"
            type='text'
            data-kt-user-table-filter='search'
            className='form-control form-control-solid w-250px ps-14'
            placeholder='Search People'
            value={searchInput}
            onChange={handleInputChange}
          />
        </h3>
        <div className='card-toolbar d-flex align-items-center'>
          <div className='mx-4'>
            <select
              className='form-select form-select-solid form-select-lg fw-bold'
              value={selectedSubcategory}
              onChange={(e) => setSelectedSubcategory(e.target.value)}
            >
              <option value=''>Select a Type...</option>
              {Array.isArray(subcategories) && subcategories.length > 0 ? (
                subcategories.map((subcategory) => (
                  <option key={subcategory.id} value={subcategory.id}>
                    {subcategory.title}
                  </option>
                ))
              ) : (
                <option value='' disabled>No Categories Available</option>
              )}
            </select>
          </div>
          <div className='mx-4'>
            <select
              name='type'
              className='form-select form-select-solid form-select-lg fw-bold'
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
            >
              <option value=''>Filter by Status...</option>
              <option value={1}>Active</option>
              <option value={0}>Inactive</option>
            </select>
          </div>
        </div>
      </div>
      <div className='card-header border-0 pt-5 mx-3 d-flex flex-column align-items-end'>
        <div>
          <button
            className='btn btn-primary me-3'
            onClick={handleApplyFilters}
          >
            Apply Filters
          </button>
          <button
            className='btn btn-light-danger'
            onClick={handleClearFilters}
          >
            Clear Filters
          </button>
        </div>
      </div>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* Headings of columns in table  */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-140px'>
                  Full Name
                  <span onClick={() => handleSort('first_name')}>
                    <KTIcon iconName='up-down' className='fs-4 cursor-pointer' />
                  </span>
                </th>
                <th className='min-w-140px'>
                  Email
                  <span onClick={() => handleSort('email')}>
                    <KTIcon iconName='up-down' className='fs-4 cursor-pointer' />
                  </span>
                </th>
                <th className='min-w-100px'>
                  Status
                  <span onClick={() => handleSort('status')}>
                    <KTIcon iconName='up-down' className='fs-4 cursor-pointer' />
                  </span>
                </th>
                <th className='min-w-100px'>
                  Updated By
                  <span onClick={() => handleSort('updated_by')}>
                    <KTIcon iconName='up-down' className='fs-4 cursor-pointer' />
                  </span>
                </th>
                <th className='min-w-150px'>
                  Updated At
                  <span onClick={() => handleSort('updated_at')}>
                    <KTIcon iconName='up-down' className='fs-4 cursor-pointer' />
                  </span>
                </th>
                {people_activity_log || people_delete || people_edit || people_view || people_create ? (
                  <th className='min-w-100px text-center'>Actions</th>
                ) : null}
              </tr>
            </thead>
            <tbody>
              {isAllPeopleError ? (
                <tr>
                  <td>Error loading data. Please try again later.</td>
                </tr>
              ) : isAllPeopleLoading ? (
                <tr>
                  <td colSpan={6}>
                    <div className='text-center'>
                      <div className='spinner-border' role='status'>
                        <span className='visually-hidden'>Loading...</span>
                      </div>
                    </div>
                  </td>
                </tr>
              ) : peopleList.length > 0 ? (
                visibleData.map((person) => (
                  <tr key={person.id}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <h3 className='text-dark fw-bold cursor-pointer fs-6'>
                            {person.first_name + " " + person.last_name}
                          </h3>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <h3 className='text-dark fw-bold cursor-pointer fs-6'>
                            {person.email}
                          </h3>
                        </div>
                      </div>
                    </td>
                    <td>{person.status === 1 ? (
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <h3 className='fw-bold cursor-pointer fs-6 badge badge-success'>
                            Active
                          </h3>
                        </div>
                      </div>
                    ) : (
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <h3 className='fw-bold cursor-pointer fs-6 badge badge-danger'>Inactive</h3>
                        </div>
                      </div>
                    )}
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start cursor-pointer'>
                          {person.updated_by}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start cursor-pointer'>
                          {formatDateWithTime(person.updated_at)}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex justify-content-end flex-shrink-0'>
                        {people_edit && (
                          <button
                            data-toggle="tooltip" data-placement="bottom" title="Edit Person"
                            className='btn btn-icon btn-light-dark btn-active-color-warning btn-sm me-1'
                            onClick={() => {
                              handlePeopleEdit(person);
                            }}
                          >
                            <KTIcon iconName='pencil' className='fs-3' />
                          </button>
                        )}
                        {people_view && (
                          <button
                            data-toggle="tooltip" data-placement="bottom" title="See Person Detail"
                            onClick={() => handleViewPeople(person)}
                            className='btn btn-icon btn-light-dark btn-active-color-warning btn-sm me-1'
                          >
                            <KTIcon iconName='eye' className='fs-3' />
                          </button>
                        )}
                        {people_activity_log && (
                          <button
                            onClick={() => {
                              handlePeopleLogs(person);
                            }}
                            className='btn btn-icon btn-light-dark btn-active-color-warning btn-sm me-1'
                            data-bs-toggle='tooltip'
                            data-bs-placement='top'
                            data-bs-custom-class='tooltip-dark'
                            title='Person Log'
                          >
                            <i className='bi bi-journal-text'></i>
                          </button>
                        )}
                        {people_create && (
                          <button
                            data-toggle="tooltip" data-placement="bottom" title="Update Status"
                            onClick={() => handleStatusUpdate.mutate(person.id)}
                            className='btn btn-icon btn-light-dark btn-active-color-warning btn-sm me-1'
                          >
                            <KTIcon iconName='update-folder' className='fs-3' />
                          </button>
                        )}
                        {people_delete && (
                          <button
                            data-toggle="tooltip" data-placement="bottom" title="Delete person"
                            className='btn btn-icon btn-light-dark btn-active-color-danger btn-sm'
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_1'
                            onClick={() => {
                              setSelectedPerson({ ...person })
                            }}
                          >
                            <KTIcon iconName='trash' className='fs-3' />
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>No People Available.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {/* Delete modal */}
      <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Delete Person Confirmation</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              {selectedPerson ? (
                <>
                  <p className='mb-2'>
                    <span className='fw-bold p-3'>Full Name</span>{selectedPerson.first_name + ' ' + selectedPerson.last_name}
                  </p>
                  <p className='mb-2'>
                    <span className='fw-bold p-3'>Email:</span> {selectedPerson.email}
                  </p>
                </>
              ) : null}
            </div>
            <div className='modal-footer'>
              <button data-toggle="tooltip" data-placement="bottom" title="Close" type='button' className='btn btn-light' data-bs-dismiss='modal'>
                Close
              </button>
              <button
                data-toggle="tooltip" data-placement="bottom" title="Delete this Person"
                type='button'
                className='btn btn-danger'
                data-bs-dismiss='modal'
                onClick={() => {
                  if (selectedPerson) {
                    handleDeletePerson.mutate(selectedPerson.id)
                  }
                }}
              >
                Delete Person
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='card-footer d-flex justify-content-between py-4'>
        <div className='align-items-center row'>
          <div className='d-flex'>
            <div className='col-md-9 mt-5'><strong>Results Per Page:</strong></div>
            <select
              className='form-select-md'
              value={selectedItemsPerPage}
              onChange={(e) => setSelectedItemsPerPage(Number(e.target.value))}
            >
              {itemsPerPageOptions.map(option => (
                <option key={option} value={option} className='p-3 '>{option}</option>
              ))}
            </select>
          </div>
        </div>
        <div className='d-flex justify-content-end'>
          {currentPage === 1 ? (
            <button
              className='btn btn-light'
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              Previous
            </button>
          ) : (
            <button
              className='btn btn-primary'
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              Previous
            </button>
          )}
          <button
            className='btn btn-primary ms-2'
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  )
}

export default PeopleList