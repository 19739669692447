import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { get_single_user } from '../api/usersApi';
import { useQuery } from 'react-query';
type User = {
  id: number;
  title: string | undefined;
  picture: string | undefined;
  address: string | undefined;
  email: string | undefined;
  firstname: string | undefined;
  middlename: string | undefined;
  role: string | undefined;
  lastname: string | undefined;
  mobile: string | undefined;
  telephone: string | undefined;
  status: number | undefined;
  created_at: string;
  updated_at: string;
};
function ViewUser() {
  const [selectedUser, setSelectedUser] = useState<User | null>();
  const location = useLocation();
  // const id = (location.state as { id: number }).id;
  const { data:viewUser, isLoading:viewIsLoading, error:viewIsError } = useQuery(['ViewUser'], () =>
  get_single_user(localStorage.getItem('current_user_id')), {refetchOnWindowFocus: false}
);
  useEffect(() => {
    if(viewUser){
        setSelectedUser(viewUser.data[0]);
    }
  }, [viewUser]);
  const navigate = useNavigate();
  if (viewIsLoading) {
    return (
      <div className='text-center'>
        <div className='spinner-border' role='status'>
          <span className='visually-hidden'>Loading...</span>
        </div>
      </div>
    );
  }
  if (viewIsError) {
    return (
      <div className='text-center'>
        <h2>Error in Get All Users</h2>
      </div>
    );
  };
  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        // data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>View User</span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
          <button
            data-toggle="tooltip" data-placement="bottom" title="Navigate to List Vendors"
            className='btn btn-sm btn-light-dark fs-5'
            onClick={() => navigate('/users/users-list')}
          >
            List of Users
          </button>
        </div>
      </div>
      <div id='kt_account_profile_details' className='collapse show'>
        <div className='card-body border-top p-9'>
          {selectedUser ? (
            <>
              {/* ... View Form ... */}
              <form>
                <div className='form-group row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span className='required'>Full Name</span>
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <label className='form-control form-control-lg form-control-solid'>
                      {selectedUser.title} {selectedUser.firstname}  {selectedUser.middlename}  {selectedUser.lastname}
                    </label>
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Telephone</label>
                  <div className='col-lg-8 fv-row'>
                    <label className='form-control form-control-lg form-control-solid'>
                      {selectedUser.telephone}
                    </label>
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span className='required'>Role</span>
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <label className='form-control form-control-lg form-control-solid'>
                      {selectedUser.role}
                    </label>
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span className='required'>Phone Number</span>
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <label className='form-control form-control-lg form-control-solid'>
                      {selectedUser.mobile}
                    </label>
                  </div>
                </div>
                <div className='form-group row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span className='required'>Email</span>
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <label className='form-control form-control-lg form-control-solid'>
                      {selectedUser.email}
                    </label>
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span>Address</span>
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <label className='form-control form-control-lg form-control-solid'>
                      {selectedUser.address}
                    </label>
                  </div>
                </div>
                <div className='form-group row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6 '>
                    Picture
                  </label>
                  <div className='col-lg-8 fv-row'>
                    {selectedUser.picture ? (
                      <img
                        src={selectedUser.picture}
                        style={{ width: '200px', height: '200px' }}
                        alt='Product'
                        className='product-image'
                      />
                    ) :
                      (
                        <div className="symbol symbol-circle symbol-75px">
                          {selectedUser.firstname ? (<div className="symbol-label  fs-1 fw-bold text-inverse-dark bg-dark">{selectedUser.firstname[0].toUpperCase()}</div>) : null}
                        </div>
                      )
                    }
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span >Status</span>
                  </label>
                  <div className='col-lg-8 fv-row'>
                    {selectedUser.status === 1 ? (
                      <label className='form-control form-control-lg form-control-solid'>
                        Active
                      </label>
                    ) : (
                      <label className='form-control form-control-lg form-control-solid'>
                        Inactive
                      </label>
                    )}
                  </div>
                </div>
              </form>
            </>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default ViewUser;