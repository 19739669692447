import axios from "axios";
import secureLocalStorage from "react-secure-storage";
export const get_upcoming_appoitnments = async (userId) =>{
    const response = await axios.get(secureLocalStorage.getItem("baseURL") + '/api/upcoming-appointments/' + userId)
    return response.data;
}
export const get_appoitnments_by_locations = async (locationId) =>{
    const response = await axios.get(secureLocalStorage.getItem("baseURL") + '/api/appointments-by-location/' + locationId)
    return response.data;
}
export const get_auth_user  = async () =>{
    const response = await axios.get(secureLocalStorage.getItem("baseURL") + '/api/auth-user')
    return response.data;
}