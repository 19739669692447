import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { KTIcon } from '../../../../../_metronic/helpers';
import { KTSVG } from '../../../../../_metronic/helpers';
import { toast } from 'react-toastify';
import { formatDateWithTime } from '../../../../helper-functions/DateFunction';
import { change_active_status_role, delete_role, get_all_filters_status_roles, get_all_roles } from '../api/rolesApi';
import { permissionCheck } from '../../../../helper-functions/PermissionCheck';
type Role = {
  id: number;
  name: string | undefined;
  status: number | undefined;
  updated_by: string;
  updated_at: string;
};
var role_create, role_edit, role_delete ,role_activity_log
function RolesList() {
  const [roleList, setRoleList] = useState<Role[]>([]);
  const [selectedRole, setSelectedRole] = useState<Role | null>(null)
  const [selectedItemsPerPage, setSelectedItemsPerPage] = useState(10); // Step 1: State variable for items per page
  const [sortBy, setSortBy] = useState<{ key: string, ascending: boolean } | null>(null); // State variable for sorting
  const [searchInput, setSearchInput] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [visibleData, setVisibleData] = useState<Role[]>([]);
  role_create = permissionCheck('role-create')
  role_edit = permissionCheck('role-update')
  role_delete = permissionCheck('role-delete')
  role_activity_log = permissionCheck('role-activity-log')
  const { data: filterData, isLoading, isError, refetch: filterRefetchRoles } = useQuery(
      ['filterData', selectedStatus],
      () => get_all_filters_status_roles(selectedStatus),
      {
        onSuccess: (filteredData) => {
          setRoleList(filteredData.data);
        },
        onError: (error: any) => {
          if (error.response) {
            const errorMessage = error.response.data.message;
            toast.error(errorMessage, { position: 'top-right', autoClose: 4000 });
          }
        },
        enabled: true, // Keep it disabled for manual triggers
        refetchOnWindowFocus: false,
      }
    );
  useEffect(()=>{
    if(filterData){
      setRoleList(filterData.data)
    }
  }, [filterData])
  const itemsPerPageOptions = [10, 20, 30, 50, 100]; // Step 2: Options for items per page
  const totalItems =roleList.length;
  const totalPages = Math.ceil(totalItems / selectedItemsPerPage); // Update total pages based on selected items per page
  useEffect(() => {
    if (roleList.length > 0) {
      let sortedData = [...roleList];
      if (sortBy) {
        sortedData.sort((a, b) => {
          if (sortBy && sortBy.key === 'status' && typeof a.status === 'number' && typeof b.status === 'number') {
            return sortBy.ascending ? a.status - b.status : b.status - a.status;
          } else {
            const aValue = (a[sortBy?.key] || '') as string;
            const bValue = (b[sortBy?.key] || '') as string;
            return sortBy && sortBy.ascending ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
          }
        });
      }
      setVisibleData(
        sortedData.slice(
          (currentPage - 1) * selectedItemsPerPage, // Update slice based on selected items per page
          currentPage * selectedItemsPerPage
        )
      );
    }
  }, [roleList, currentPage, selectedItemsPerPage, sortBy]);
  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    const searchTerm = e.currentTarget.value;
    setSearchInput(searchTerm);
    const filteredItems = filterData.data.filter((role) => {
      return role.name?.toLowerCase().includes(searchTerm.toLowerCase());
    })
    setRoleList(filteredItems);
  };
  const handleDeleteRole = useMutation((id: number) => {
    return delete_role(id)
      .then((response) => {
        toast.success(response.message, {
          position: 'top-right',
          autoClose: 3000,
        })
        get_all_roles()
          .then((data) => {
            setRoleList(data.data)
          })
          .catch((error: any) => {
            if (error.response) {
                      const errorMessage = error.response.data.message;
                      // Show error toast with HTML content
                      toast.error(
                        <div dangerouslySetInnerHTML={{ __html: errorMessage }} />,
                        {
                          position: 'top-right',
                          autoClose: 6000,
                        }
                      );
                    }
          })
      })
      .catch((error) => {
        if (error.response) {
          const errorMessage = error.response.data.message
          toast.error(errorMessage, {
            position: 'top-right',
            autoClose: 2000,
          })
        }
      })
  });
  const handleStatusUpdate = useMutation((id: number) => {
    return change_active_status_role(id)
      .then((response) => {
        toast.success(response.message, {
          position: 'top-right',
          autoClose: 3000,
        });
        return get_all_roles().then((data) => {
          setRoleList(data.data);
        });
      })
      .catch((error: any) => {
        if (error.response) {
          const errorMessage = error.response.data.message;
          // Show error toast with HTML content
          toast.error(
            <div dangerouslySetInnerHTML={{ __html: errorMessage }} />,
            {
              position: 'top-right',
              autoClose: 6000,
            }
          );
        }
      });
  });
   // Handle Sort
   const handleSort = (key: string) => {
    if (!sortBy || sortBy.key !== key || sortBy.ascending) {
      setSortBy({ key, ascending: false }); // Sort in descending order initially
    }else if(key==="updated_by"){
      // Toggle between ascending and descending order
      setSortBy({ key, ascending: !sortBy.ascending });
    }
     else {
      setSortBy(null); // Revert to unsorted state
    }
  };
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  // Handle for Update Role
  const handleUpdate = (role: any) => {
    navigate("/users/roles/edit-role", { state: { role: role } });
  };
  const handleSingleRoleLogs = (role: any) => {
    navigate("/users/roles/role-logs", { state: { id: role.id } });
  };
  return (
    <div className='card card-xxl-stretch mx-4 mb-5 mb-xl-10'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>List of Roles</span>
        </h3>
        {role_create && (
          <div
          className='card-toolbar'
        >
          <button
            data-toggle="tooltip" data-placement="bottom" title="Navigate to Add New Role"
            className='btn btn-sm btn-light-dark'
            onClick={() => navigate('/users/roles/add-role')}
          >
            <KTIcon iconName='plus' className='fs-3' />
            Add Role
          </button>
        </div>
        )}        
      </div>
      {/*Search And Filter*/}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
          <input
            data-toggle="tooltip" data-placement="bottom" title="Search a Role"
            type='text'
            data-kt-user-table-filter='search'
            className='form-control form-control-solid w-250px ps-14'
            placeholder='Search Role'
            value={searchInput}
            onChange={handleInputChange}
          />
        </h3>
        <div className='card-toolbar'>
          <h5 className='p-3'>Filter by:</h5>
          <div>
            <select
              className='form-select form-select-solid form-select-lg fw-bold'
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
            >
              <option value=''>Filter by Status...</option>
              <option value={1}>Active</option>
              <option value={0}>Inactive</option>
            </select>
          </div>
        </div>
      </div>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted'>
              <th className='min-w-150px'>Role Title <span onClick={() => handleSort('name')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                <th className='min-w-150px'>Status <span onClick={() => handleSort('status')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                <th className='min-w-140px'>Updated By <span onClick={() => handleSort('updated_by')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                <th className='min-w-120px'>Updated At <span onClick={() => handleSort('updated_at')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                {role_create || role_edit || role_delete ? (
                  <th className='min-w-100px text-center'>Actions</th>
                ) : null}                
              </tr>
            </thead>
            <tbody>
              {isError ? (
                <tr>
                  <td>Error loading data. Please try again later.</td>
                </tr>
              ) : isLoading ? (
                <tr>
                  <td colSpan={6}>
                    <div className='text-center'>
                      <div className='spinner-border' role='status'>
                        <span className='visually-hidden'>Loading...</span>
                      </div>
                    </div>
                  </td>
                </tr>
              ) : roleList.length > 0 ? (
                visibleData.map((role) => (
                  <tr key={role.id}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <h3 className='text-dark fw-bold cursor-pointer fs-6'>
                            {role.name}
                          </h3>
                        </div>
                      </div>
                    </td>
                    <td>{role.status === 1 ? (
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <h3 className='fw-bold cursor-pointer fs-6 badge badge-success'>
                            Active
                          </h3>
                        </div>
                      </div>
                    ) : (
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <h3 className='fw-bold cursor-pointer fs-6 badge badge-danger'>
                            Inactive
                          </h3>
                        </div>
                      </div>
                    )}
                    </td>
                    <td>
                      <div className='d-flex flex-column w-100 me-2'>
                        <div className='d-flex flex-stack mb-2 cursor-pointer'>
                          {role.updated_by}
                        </div>
                      </div>
                    </td>
                    <td className='text-end'>
                      <div className='d-flex flex-column w-100 me-2'>
                        <div className='d-flex flex-stack mb-2 cursor-pointer'>
                          {formatDateWithTime(role.updated_at)}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex justify-content-end flex-shrink-0'>
                        {role_edit && (
                          <button
                            onClick={() => handleUpdate(role)}
                            data-toggle="tooltip" data-placement="bottom" title="Edit Role"
                            className='btn btn-icon btn-light-dark btn-active-color-warning btn-sm me-1'
                          >
                            <KTIcon iconName='pencil' className='fs-3' />
                          </button>
                        )}
                        {role_activity_log && (
                          <button
                            onClick={() => {
                              handleSingleRoleLogs(role);
                            }}
                            className='btn btn-icon btn-light-dark btn-active-color-warning btn-sm me-1'
                            data-bs-toggle='tooltip'
                            data-bs-placement='top'
                            data-bs-custom-class='tooltip-dark'
                            title='Role Logs'
                          >
                            <i className='bi bi-journal-text'></i>
                          </button>
                        )}
                        {role_create && (
                          <button
                          data-toggle="tooltip" data-placement="bottom" title="update Status"
                          onClick={() => handleStatusUpdate.mutate(role.id)}
                          className='btn btn-icon btn-light-dark btn-active-color-warning btn-sm me-1'
                        >
                          <KTIcon iconName='update-folder' className='fs-3' />
                        </button>
                        )}
                        {role_delete && (
                          <button
                          data-toggle="tooltip" data-placement="bottom" title="Delete Role"
                          onClick={() => setSelectedRole({ ...role })}
                          className='btn btn-icon btn-light-dark btn-active-color-danger btn-sm'
                          data-bs-toggle='modal'
                          data-bs-target='#kt_modal_1'
                        >
                          <KTIcon iconName='trash' className='fs-3' />
                        </button>
                        )}                        
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>No Roles Available.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className='card-footer d-flex justify-content-between py-4'>
          <div className='align-items-center row'>
            <div className='d-flex'>
              <div className='col-md-9 mt-5'><strong>Results Per Page:</strong></div>
              <select
                className='form-select-md'
                value={selectedItemsPerPage}
                onChange={(e) => setSelectedItemsPerPage(Number(e.target.value))}
              >
                {itemsPerPageOptions.map(option => (
                  <option key={option} value={option} className='p-3 '>{option}</option>
                ))}
              </select>
            </div>
          </div>
          <div className='d-flex justify-content-end'>
            {currentPage === 1 ? (
              <button
                className='btn btn-light'
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
              >
                Previous
              </button>
            ) : (
              <button
                className='btn btn-primary'
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
              >
                Previous
              </button>
            )}
            <button
              className='btn btn-primary ms-2'
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Delete Role Confirmation</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              {selectedRole ? (
                <>
                  <p className='mb-2'>
                    <span className='fw-bold fs-5'>Role Name:</span> {selectedRole.name}
                  </p>
                </>
              ) : null}
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                Close
              </button>
              <button
                type='button'
                className='btn btn-danger'
                onClick={() => {
                  if (selectedRole) {
                    handleDeleteRole.mutate(selectedRole.id)
                  }
                }}
                data-bs-dismiss='modal'
              >
                Delete Role
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
};

export default RolesList