import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { allLocationType, allPeople } from '../../../modules/accounts/components/settings/SettingsModel';
import { get_all_active_categories } from '../../categories/api/categoriesApi';
import { get_all_subcategories_against_category } from '../../subcategories/api/subcategoriesApi';
import { get_all_meeting_locations, get_all_virtual_platforms } from '../../locations/api/locationsApi';
import Select from 'react-select';
import { get_all_people, get_single_person, get_single_person_data, update_people } from '../api/peopleApi';
import { getCountries } from '../../../helper-functions/Countries';
import { useAuth } from '../../../modules/auth';
import { getSettingValue } from '../../../helper-functions/general_settings_helper';

interface Category {
  id: number;
  title: string;
};
interface Subcategory {
  id: number;
  title: string;
  categories_id: number | undefined;
  categories_title: string | undefined;
};

function EditPeople() {
  const [peopleList, setPeopleList] = useState<allPeople[]>([]);
  const [selectedPerson, setSelectedPerson] = useState<any | null>();
  const [userPicture, setUserPicture] = useState<File | null>(null);
  const [locations, setLocations] = useState<allLocationType[]>([]);
  const [platforms, setPlatforms] = useState<allLocationType[]>([])
  const [categories, setCategories] = useState<Category[]>([]);
  const [subcategories, setSubcategories] = useState<Subcategory[]>([]);
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [personId, setPersonId] = useState<number | null>(null);
  const [subcategoryValidationError, setSubcategoryValidationError] = useState('');
  const [titleError, setTitleError] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [address1Error, setAddress1Error] = useState('');
  const [townError, settownError] = useState('');
  const [cityError, setCityError] = useState('');
  const [countryError, setCountryError] = useState('');
  const [postCodeError, setPostCodeError] = useState('');
  const [emailValidationError, setEmailValidationError] = useState('');
  const [mobileValidationError, setMobileValidationError] = useState('');
  const [loading, setLoading] = useState(false);
   const [selectedCategories, setSelectedCategories] = useState<Category[]>([]);
  const [selectedSubcategories, setSelectedSubcategories] = useState<Subcategory[]>([]);
  const location = useLocation();
  const { auth } = useAuth();
  const isPeople = auth?.user?.is_people;
  const id = isPeople ? auth?.id : (location.state as { id: number }).id;
  const { data } = useQuery(['ViewPerson', id], () =>
    setPersonId(id), {refetchOnWindowFocus: false}
  );
  const { data: singlePersonData, isLoading: isSinglePersonLoading, isError, refetch } = useQuery(
    ['singlePerson', personId],
    () => isPeople? get_single_person_data(personId!) : get_single_person(personId!),
    {
      refetchOnWindowFocus: false, 
      enabled: !!personId,
    }
  );
  useEffect(() => {
    if (singlePersonData) {
      setSelectedPerson(singlePersonData.data as allPeople);
    }
  }, [singlePersonData]);
  //getting categories data
  const { data: categoryData } = useQuery('categories', get_all_active_categories, {refetchOnWindowFocus: false})
  useEffect(() => {
    if (categoryData) {
      setCategories(categoryData.data as Category[])
    }
  }, [categoryData]);
  // function to change the subcategories list
  const handleCategoryChage = (selectedOptions) => {
    selectedOptions.map((option) => {
      setSelectedId(option?.value)
    })
  }
  //getting subcategories data based on category
  const { data: subcategoryData } = useQuery('subcategory', () => get_all_subcategories_against_category(selectedId), {refetchOnWindowFocus: false})
  useEffect(() => {
    if (subcategoryData && selectedId) {
      setSubcategories(subcategoryData.data as Subcategory[])
    }
  }, [subcategoryData, selectedId])

  const { data: platformData } = useQuery('platforms', get_all_virtual_platforms, {refetchOnWindowFocus: false})
  useEffect(() => {
    if (platformData) {
      setPlatforms(platformData.data as allLocationType[])
    }
  }, [platformData])
  // getting all locations
  const { data: locationData } = useQuery('locations', get_all_meeting_locations, {refetchOnWindowFocus: false})
  useEffect(() => {
    if (locationData) {
      setLocations(locationData.data as allLocationType[])
    }
  }, [locationData])
  const ref = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const handleSubcategoryChange = (selectedOptions) => {
    if (!selectedOptions || selectedOptions.length === 0) {
      setSubcategoryValidationError('Subcategory is required.');
    } else {
      setSubcategoryValidationError('');
    }
    if (selectedPerson) {
      const updatedSubcategories = selectedOptions.map((selectedOption, index) => ({
        id: index + 1,
        sub_category_id: parseInt(selectedOption.value, 10),
        sub_category_title: selectedOption.label,
      }));
      setSelectedPerson({
        ...selectedPerson,
        sub_categories: updatedSubcategories,
      });
    }
  };
  const handleTitleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (selectedPerson) {
      const newTitleName = e.target.value;
      if (!newTitleName) {
        setTitleError('Title is required.');
      } else {
        setTitleError('');
      };
      setSelectedPerson({
        ...selectedPerson,
        title: newTitleName,
      });
    };
  };
  const handleFirstNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (selectedPerson) {
      const newFirstName = e.target.value;
      if (!newFirstName) {
        setFirstNameError('First Name is required.');
      } else {
        setFirstNameError('');
      };
      setSelectedPerson({
        ...selectedPerson,
        first_name: newFirstName,
      });
    };
  };
  const handleMidddleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (selectedPerson) {
      const middleName = e.target.value;
      setSelectedPerson({
        ...selectedPerson,
        middle_name: middleName,
      });
    };
  };
  const handleLastNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (selectedPerson) {
      const lastName = e.target.value;
      if (!lastName) {
        setLastNameError('Last Name is required.');
      } else {
        setLastNameError('');
      };
      setSelectedPerson({
        ...selectedPerson,
        last_name: lastName,
      });
    };
  };
  const handleAddress1Change = (e: ChangeEvent<HTMLInputElement>) => {
    if (selectedPerson) {
      const address1 = e.target.value;
      if (!address1) {
        setAddress1Error('Address Line 1 Name is required.');
      } else {
        setAddress1Error('');
      };
      setSelectedPerson({
        ...selectedPerson,
        address_line_1: address1,
      });
    };
  };
  const handleAddress2Change = (e: ChangeEvent<HTMLInputElement>) => {
    if (selectedPerson) {
      const address2 = e.target.value;
      setSelectedPerson({
        ...selectedPerson,
        address_line_2: address2,
      });
    };
  };
  const handleTownChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (selectedPerson) {
      const town = e.target.value;
      if (!town) {
        settownError('Town is required.');
      } else {
        settownError('');
      };
      setSelectedPerson({
        ...selectedPerson,
        town: town,
      });
    };
  };
  const handleCityChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (selectedPerson) {
      const city = e.target.value;
      if (!city) {
        setCityError('City is required.');
      } else {
        setCityError('');
      };
      setSelectedPerson({
        ...selectedPerson,
        city: city,
      });
    };
  };
  const handleCountryChange = (e) => {
    if (selectedPerson) {
      const country = e.target.value;
      if (!country) {
        setCountryError('Country is required.');
      } else {
        setCountryError('');
      };
      setSelectedPerson({
        ...selectedPerson,
        country: country,
      });
    };
  };
  const handlePostCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (selectedPerson) {
      const postCode = e.target.value;
      if (!postCode) {
        setPostCodeError('Post Code is required.');
      } else {
        setPostCodeError('');
      };
      setSelectedPerson({
        ...selectedPerson,
        postal_code: postCode,
      });
    };
  };
  const handleRateChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (selectedPerson) {
      const newRate = e.target.value;
      setSelectedPerson({
        ...selectedPerson,
        appointment_rate: newRate,
      });
    };
  };
  const handleMobileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (selectedPerson) {
      const newMobile = e.target.value.trim();
      setSelectedPerson({
        ...selectedPerson,
        mobile_number: newMobile,
      });
      if (!newMobile) {
        setMobileValidationError('Mobile Number is required.');
      } else {
        const numberRegex = /^[+0-9]+$/;
        if (!numberRegex.test(newMobile)) {
          setMobileValidationError('Mobile Number must be in numeric value.');
        } else {
          setMobileValidationError('');
        };
      };
    };
  };
  const handlePhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (selectedPerson) {
      const newTelephone = e.target.value;
      setSelectedPerson({
        ...selectedPerson,
        phone_number: newTelephone,
      });
    }
  };
  const handleOtherinfoChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (selectedPerson) {
      const otherInfo = e.target.value;
      setSelectedPerson({
        ...selectedPerson,
        other_information: otherInfo,
      });
    };
  };
  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (selectedPerson) {
      const newEmail = e.target.value;
      if (!newEmail) {
        setEmailValidationError('Email is required.');
      } else {
        setEmailValidationError('');
      };
      setSelectedPerson({
        ...selectedPerson,
        email: newEmail,
      });
    };
  };
  const handlePeopleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (selectedPerson) {
      const newPicture = e.target.files?.[0] || null;
      setSelectedPerson({
        ...selectedPerson,
        picture: newPicture ? URL.createObjectURL(newPicture) : undefined,
      });
      setUserPicture(newPicture);
    };
  };
  const handlePlatformsChange = (selectedOptions) => {
    if (selectedPerson) {
      const updatedPlatforms = selectedOptions.map((selectedOption, index) => ({
        id: index + 1,
        location_id: parseInt(selectedOption.value, 10),
        platform_name: selectedOption.label,
      }));
      setSelectedPerson({
        ...selectedPerson,
        people_platforms: updatedPlatforms,
      });
    }
  };
  const handleLocationChange = (selectedOptions) => {
    if (selectedPerson) {
      const updatedLocation = selectedOptions.map((selectedOption, index) => ({
        id: index + 1,
        location_id: parseInt(selectedOption.value, 10),
        platform_name: selectedOption.label,
      }));
      setSelectedPerson({
        ...selectedPerson,
        people_locations: updatedLocation,
      });
    }
  };
  const handleStatusChange = (e: any) => {
    if (selectedPerson) {
      const status = e.target.value;
      setSelectedPerson({
        ...selectedPerson,
        status: status,
      });
    }
  };
  const updatePeopleMutation = useMutation(update_people, {
    onSuccess: (response) => {
      refetch();
      setLoading(false);
      toast.success(response.message, {
        position: 'top-right',
        autoClose: 3000,
      });
      ref.current && (ref.current.value = '')
    },
    onError: (error: any) => {
      if (error.response) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage, {
          position: 'top-right',
          autoClose: 2000,
        })
      }
      setLoading(false);
    },
  });
  const handleUpdatePeople = () => {
    setLoading(true);
    if (selectedPerson) {
      const { id,
        sub_categories,
        title,
        first_name,
        middle_name,
        last_name,
        address_line_1,
        address_line_2,
        town,
        city,
        postal_code,
        country,
        mobile_number,
        phone_number,
        other_information,
        email,
        password,
        picture,
        people_locations,
        people_platforms,
        confirm_password,
        appointment_rate,
        status
      } = selectedPerson;

      const updatedSubcategories = sub_categories.map((subcategory) => ({
        id: subcategory.id,
        people_id: subcategory.people_id,
        people_title: subcategory.people_title,
        sub_category_id: subcategory.sub_category_id,
        sub_category_title: subcategory.sub_category_title,
      }));
      const updatedLocations = people_locations.map((location) => ({
        id: location.id,
        location_id: location.location_id,
        platform_name: location.platform_name,
      }));
      const updatedPlatforms = people_platforms.map((platform) => ({
        id: platform.id,
        location_id: platform.location_id,
        platform_name: platform.platform_name,
      }));
      updatePeopleMutation.mutate({
        id,
        title,
        first_name,
        middle_name,
        last_name,
        address_line_1,
        address_line_2,
        town,
        city,
        postal_code,
        country,
        mobile_number,
        phone_number,
        picture: userPicture,
        other_information,
        email,
        password,
        confirm_password,
        appointment_rate,
        sub_categories:updatedSubcategories,
        people_locations: updatedLocations,
        people_platforms: updatedPlatforms
      });
    };
  };
  if (isError) {
    return (
      <div className='text-center'>
        <h2>Error in Geting People</h2>
      </div>
    );
  };
  if(isSinglePersonLoading){
    return (
      <div className='text-center'>
      <div className='spinner-border' role='status'>
        <span className='visually-hidden'>Loading...</span>
      </div>
    </div>
    )
  }
  const filteredSubcategories = selectedPerson?.active_subcategories_against_categories.filter((subcategory) =>
    selectedPerson?.selected_category_ids?.some((category) => category.id === subcategory.categories_id)
  );
  const selectedSubcategoryIds = selectedPerson?.sub_categories?.map((subcategory) => subcategory.sub_category_id);
  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0'
      >
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>{isPeople? 'My Profile' : 'Edit Person'}</span>
        </h3>
        <div
          className='card-toolbar'
        >
          <button
            data-toggle="tooltip" data-placement="bottom" title="Navigate to List of People"
            className='btn btn-sm btn-light-dark fs-5'
            onClick={() => navigate('/people/people-list')}
          >
            List of People
          </button>
        </div>
      </div>
      <div id='kt_account_profile_details' className='collapse show'>
        <div className='card-body border-top p-9'>
          {selectedPerson ? (
            <>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Category</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <Select
                    isMulti
                    placeholder="Select Categories... "
                    options={selectedPerson?.active_categories_data?.map((category) => ({
                      value: category.id,
                      label: category.title,
                    }))}
                    value={selectedPerson?.selected_category_ids?.map((category) => ({
                      value: category.id,
                      label: category.title,
                    }))}
                    className="basic-multi-select form-select-lg p-2 bg-light text-black"
                    classNamePrefix="select border-light fw-bold"
                    onChange={(selectedOptions) => {
                      // Update selected categories
                      const updatedCategories = selectedOptions.map((option) => ({
                        id: option.value,
                        title: option.label,
                      }));
                      setSelectedPerson({
                        ...selectedPerson,
                        selected_category_ids: updatedCategories,
                      });
                    }}
                  />
                </div>
              </div>
              <div className='row mb-6'>
  <label className='col-lg-4 col-form-label fw-bold fs-6'>
    <span className='required'>Subcategory</span>
  </label>
  <div className='col-lg-8 fv-row'>
    <Select
      isMulti
      placeholder={filteredSubcategories?.length ? "Select Subcategory" : "No Subcategories Available"}
      className="basic-multi-select form-select-lg p-2 bg-light text-black"
      classNamePrefix="select border-light fw-bold"
      options={filteredSubcategories?.map((subcategory) => ({
        value: subcategory.id,
        label: subcategory.title,
      }))}
      value={filteredSubcategories?.filter(subcategory =>
        selectedSubcategoryIds.includes(subcategory.id)
      ).map(subcategory => ({
        value: subcategory.id,
        label: subcategory.title,
      }))}
      onChange={(selectedOptions) => {
        const updatedSubcategories = selectedOptions.map((selectedOption, index) => ({
          id: index + 1,
          sub_category_id: parseInt(selectedOption.value, 10),
          sub_category_title: selectedOption.label,
        }));
        
        setSelectedPerson({
          ...selectedPerson,
          sub_categories: updatedSubcategories,
        });
      }}
    />
    {subcategoryValidationError && (
      <div className='text-danger'>{subcategoryValidationError}</div>
    )}
  </div>
</div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Title</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <select
                    className='form-select form-select-solid form-select-lg fw-bold'
                    value={selectedPerson ? selectedPerson.title : ''}
                    onChange={(e) => handleTitleChange(e)}
                  >
                    <option value=''>Select a Title...</option>
                    <option value='Mr.'>Mr.</option>
                    <option value='Mrs.'>Mrs.</option>
                    <option value='Ms.'>Ms.</option>
                  </select>
                  {titleError && (
                    <div className='text-danger'>{titleError}</div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  <span>First Name</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='First Name'
                    value={selectedPerson ? selectedPerson.first_name : ''}
                    onChange={(e) => handleFirstNameChange(e)}
                  />
                  {firstNameError && (
                    <div className='text-danger'>{firstNameError}</div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Middle Name</label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Middle Name'
                    value={selectedPerson ? selectedPerson.middle_name : ''}
                    onChange={(e) => handleMidddleNameChange(e)}
                  />
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Last Name</label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Last Name'
                    value={selectedPerson ? selectedPerson.last_name : ''}
                    onChange={(e) => handleLastNameChange(e)}
                  />
                  {lastNameError && (
                    <div className='text-danger'>{lastNameError}</div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Address Line 1</label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Address Line 1'
                    value={selectedPerson ? selectedPerson.address_line_1 : ''}
                    onChange={(e) => handleAddress1Change(e)}
                  />
                  {address1Error && (
                    <div className='text-danger'>{address1Error}</div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Address Line 2</label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Address Line 2'
                    value={selectedPerson ? selectedPerson.address_line_2 : ''}
                    onChange={(e) => handleAddress2Change(e)}
                  />
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Town</label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Town'
                    value={selectedPerson ? selectedPerson.town : ''}
                    onChange={(e) => handleTownChange(e)}
                  />
                  {townError && (
                    <div className='text-danger'>{townError}</div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6 required'>City</label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='City'
                    value={selectedPerson ? selectedPerson.city : ''}
                    onChange={(e) => handleCityChange(e)}
                  />
                  {cityError && (
                    <div className='text-danger'>{cityError}</div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Country </label>
            <div className='col-lg-8 fv-row'>
              <select
                className='form-select form-select-lg form-select-solid'
                value={selectedPerson ? selectedPerson.country : ''}
                onChange={(e) => handleCountryChange(e)}
              >
                <option value=''>Select a country...</option>
                {getCountries().map((country, index) => (
                  <option key={index} value={country}>
                    {country}
                  </option>
                ))}
              </select>
              {countryError && (
                    <div className='text-danger'>{countryError}</div>
                  )}
            </div>
          </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Postal Code</label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Postal Code'
                    value={selectedPerson ? selectedPerson.postal_code : ''}
                    onChange={(e) => handlePostCodeChange(e)}
                  />
                  {postCodeError && (
                    <div className='text-danger'>{postCodeError}</div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Mobile Number</label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='tel'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Mobile Number'
                    value={selectedPerson ? selectedPerson.mobile_number : ''}
                    onChange={(e) => handleMobileChange(e)}
                  />
                  {mobileValidationError && (
                    <div className='text-danger'>{mobileValidationError}</div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span>Phone Number</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='tel'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Phone Number'
                    value={selectedPerson ? selectedPerson.phone_number : ''}
                    onChange={(e) => handlePhoneChange(e)}
                  />
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Other Information</label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Other Information'
                    value={selectedPerson ? selectedPerson.other_information : ''}
                    onChange={(e) => handleOtherinfoChange(e)}
                  />
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Email</label>
                <div className='col-lg-8 fv-row'>
                <label className='form-control form-control-lg form-control-solid'>
                { selectedPerson ? selectedPerson.email : ''}
                </label>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Picture</label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='file'
                    className='form-control form-control-lg form-control-solid'
                    onChange={(e) => handlePeopleImageChange(e)}
                    name='picture'
                  />
                  <div className='text-danger mt-2'>
                    Recommended size 200 x 200 px. jpg, jpeg, or png only
                  </div>
                  {selectedPerson?.picture &&
                    <div className='row mb-6'>
                      <label className='col-lg-4 col-form-label fw-bold fs-6'>
                        Existing Picture
                      </label>
                      <div className='col-lg-8 fv-row'>
                        <img src={selectedPerson ? selectedPerson?.picture : ''} className="img-fluid img-thumbnail" width={200} height={200} alt="No Image Found" />
                      </div>
                    </div>
                  }
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6 '>Appointment Rate ({getSettingValue('currency_symbol')})</label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Appointment Rate'
                    value={selectedPerson ? selectedPerson.appointment_rate : ''}
                    onChange={(e) => handleRateChange(e)}
                  />
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span>Available Platforms</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <Select
                    isMulti
                    placeholder="Select Platforms "
                    options={platforms?.map((platform) => ({
                      value: platform.id,
                      label: platform.platform_name,
                    }))}
                    className="basic-multi-select form-select-lg p-2 bg-light text-black"
                    classNamePrefix="select border-light fw-bold"
                    value={selectedPerson.people_platforms.map((platform) => ({
                      value: platform.location_id,
                      label: platform.platform_name,
                    }))}
                    onChange={(selectedOptions) => {
                      // Assuming handleAssigneeChange receives an array of selected options
                      handlePlatformsChange(selectedOptions);
                    }}
                  />
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span>Available Locations</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <Select
                    isMulti
                    placeholder="Select Location "
                    options={locations?.map((location) => ({
                      value: location.id,
                      label: location.platform_name,
                    }))}
                    className="basic-multi-select form-select-lg p-2 bg-light text-black"
                    classNamePrefix="select border-light fw-bold"
                    value={selectedPerson.people_locations.map((location) => ({
                      value: location.location_id,
                      label: location.platform_name,
                    }))}
                    onChange={(selectedOptions) => {
                      // Assuming handleAssigneeChange receives an array of selected options
                      handleLocationChange(selectedOptions);
                    }}
                  />
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span>Status</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <Form.Select
                    className='form-select form-select-solid form-select-lg fw-bold'
                    value={selectedPerson ? selectedPerson.status : ''}
                    onChange={(e) => handleStatusChange(e)}
                  >
                    <option value={1}>Active</option>
                    <option value={0}>Inactive</option>
                  </Form.Select>
                </div>
              </div>
            </>
          ) : null}
        </div>
        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          {firstNameError || titleError || subcategoryValidationError || lastNameError || emailValidationError || townError || address1Error || cityError || countryError || postCodeError || mobileValidationError ? (
            <button type='button' data-toggle="tooltip" data-placement="bottom" title="Update Person" className='btn btn-primary disabled'>
              Update Person
            </button>
          ) : (
            <button
              data-toggle="tooltip" data-placement="bottom" title={isPeople?"Update My Profile" : "Update person"}
              type='button'
              className='btn btn-primary'
              onClick={handleUpdatePeople}
              disabled={loading}
            >
              {!loading && isPeople?"Update My Profile" : "Update person"}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default EditPeople