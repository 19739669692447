import { useEffect } from 'react';
import secureLocalStorage from 'react-secure-storage';
import { useAuth } from './core/Auth';
const CENTRAL_API_URL = process.env.REACT_APP_API_URL_CENTRAL;
export function Logout() {
  const { auth } = useAuth();
  const isPeople = auth?.user?.is_people;
  useEffect(() => {
    const handleLogout = async () => {
      const storedToken = localStorage.getItem('kt-auth-react-v');
      let access_token = '';
      if (storedToken) {
        try {
          const parsedToken = JSON.parse(storedToken);
          access_token = parsedToken?.access_token || '';
        } catch (error) {
          console.error('Error parsing access token:', error);
        }
      }
      if (!access_token) {
        console.warn('Access token is missing or invalid.');
        redirectToLogin();
        return;
      }
      try {
        // Determine API URL based on user type
        const apiUrl = isPeople === 1 ? `${secureLocalStorage.getItem('baseURL')}/api/people-logout` : `${CENTRAL_API_URL}/api/logout`;

        // Call the logout API
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${access_token}`,
          },
        });
        if (response.ok) {
          clearStorage();
        } else {
          console.error('Failed to call logout API:', await response.json());
        }
      } catch (error) {
        console.error('An error occurred during logout:', error);
      } finally {
        redirectToLogin();
      }
    };
    const clearStorage = () => {
      localStorage.removeItem('kt-auth-react-v');
      localStorage.removeItem('kt-auth-react-v-expiration');
      secureLocalStorage.removeItem('user_permissions');
      secureLocalStorage.removeItem('general_settings');
    };
    const redirectToLogin = () => {
      if (isPeople === 1) {
        window.location.href = '/auth/people/login';
      } else {
        window.location.href = '/auth/login';
      }
    };
    handleLogout();
  }, [isPeople]);

  return <></>;
}
