import React, { useEffect, useState } from "react";
import { get_all_permissions, create_role } from "../api/rolesApi";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { AddRoles, Permission } from "../../../../modules/accounts/components/settings/SettingsModel";
const AddRole = () => {
    // useNavigate
    const navigate = useNavigate();
    const [getPermission, setGetPermission] = useState<Permission[]>([]);
    // Use Effect
    const { data, isLoading, isError } = useQuery(
        "permissions",
        get_all_permissions, {refetchOnWindowFocus: false}
    );
    useEffect(() => {
        if (data) {
            setGetPermission(data.data);
        }
    }, [data]);

    // Define types for roleCheckboxes and globalCheckboxes
    type RoleCheckboxes = Record<string, Record<string, boolean>>;
    type GlobalCheckboxes = Record<string, boolean>;
    // Initialize roleCheckboxes and globalCheckboxes based on your data
    const initialRoleCheckboxes: RoleCheckboxes =
        JSON.parse(localStorage.getItem("roleCheckboxes") || "{}") || {};
    const initialGlobalCheckboxes: GlobalCheckboxes =
        JSON.parse(localStorage.getItem("globalCheckboxes") || "{}") || {};
    // I hide this function, before API integration code is working but after this is not work. So I comment the code, now functionality is working fine!
    // ;['view', 'add', 'edit', 'delete', 'restore'].forEach((permission) => {
    //   initialGlobalCheckboxes[permission] = areAllRelatedCheckboxesChecked(
    //     permission,
    //     initialRoleCheckboxes
    //   )
    // })
    const [roleCheckboxes, setRoleCheckboxes] = useState<RoleCheckboxes>(
        initialRoleCheckboxes
    );
    const [globalCheckboxes, setGlobalCheckboxes] = useState<GlobalCheckboxes>(
        initialGlobalCheckboxes
    );
    // Function to check if all related individual checkboxes are checked
    function areAllRelatedCheckboxesChecked(
        permission: string,
        roleCheckboxes: { [x: string]: { [x: string]: unknown } }
    ) {
        return getPermission
            .filter((value: { [x: string]: any }) => value[permission.toLowerCase()])
            .every(
                (value: { permission_key: string | number }) =>
                    roleCheckboxes[value.permission_key]?.[permission]
            );
    };
    // Handle individual role checkbox change
    const handleRoleCheckboxChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        role: string,
        permission: string
    ) => {
        const { checked } = event.target;
        const updatedRoleCheckboxes = { ...roleCheckboxes };
        updatedRoleCheckboxes[role] = {
            ...updatedRoleCheckboxes[role],
            [permission]: checked,
        };
        setRoleCheckboxes(updatedRoleCheckboxes);
        setGlobalCheckboxes((prevCheckboxes) => ({
            ...prevCheckboxes,
            [permission]: areAllRelatedCheckboxesChecked(
                permission,
                updatedRoleCheckboxes
            ),
        }));
    };
    // Handle global checkbox change
    const handleGlobalCheckboxChange = (permission: string) => {
        const checked = !globalCheckboxes[permission];
        const updatedRoleCheckboxes = { ...roleCheckboxes };
        getPermission.forEach((value) => {
            if (value[permission.toLowerCase()]) {
                updatedRoleCheckboxes[value.permission_key] = {
                    ...updatedRoleCheckboxes[value.permission_key],
                    [permission]: checked,
                };
            }
        });
        setRoleCheckboxes(updatedRoleCheckboxes);
        const allRelatedCheckboxesChecked = areAllRelatedCheckboxesChecked(
            permission,
            updatedRoleCheckboxes
        );
        setGlobalCheckboxes((prevCheckboxes) => ({
            ...prevCheckboxes,
            [permission]: allRelatedCheckboxesChecked,
        }));
    };
    // Update Roles
    const [roleName, setRoleName] = useState<any | null>("");
    const [status, setStatus] = useState<any | null>(1);
    const queryClient = useQueryClient();
    // Update Roles Function
    const handleUpdateRole = useMutation(
        (updatedRolesData: AddRoles) => {
            return create_role(updatedRolesData.name,updatedRolesData.organization_id, updatedRolesData.status, updatedRolesData.permissions);
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries("permissions");
            },
            onSuccess: () => {
                toast.success("Role Add Successfully", {
                    theme: "colored",
                });
                setRoleCheckboxes({});
                setGlobalCheckboxes({});
                setRoleName("");
                setStatus("");
                localStorage.removeItem("roleCheckboxes");
                localStorage.removeItem("globalCheckboxes");
                navigate("/users/roles/roles-list");
            },
            onError: (error: any) => {
                toast.error(error.response.data.message, {
                    theme: "colored",
                });
            },
        }
    );
    //Handler for creating a payload of API
    const handleSubmit = () => {
        if (roleName.length === 0) {
            toast.error("The role name field is required.", {
                theme: "colored",
            });
            return;
        }
        const selectedPermissions: string[] = [];
        let checkboxesAreEmpty = true;
        getPermission.forEach((value: { permission_key: string }) => {
            ["view", "create", "update", "delete", "activity-log"].forEach((permission) => {
                if (roleCheckboxes[value.permission_key]?.[permission]) {
                    selectedPermissions.push(`${value.permission_key}-${permission}`);
                    checkboxesAreEmpty = false;
                }
            });
        });
        if (checkboxesAreEmpty) {
            toast.error("At least one checkbox must be selected.", {
                theme: "colored",
            });
            return;
        }
        const organization:any=localStorage.getItem("organisation_id");
        const roleData = {
            name: roleName,
            status: status,
            permissions: selectedPermissions,
            organization_id:organization,
            
        };
        handleUpdateRole.mutate(roleData);
    };
    const tableCellStyle = {
        padding: "8px 10px",
    };
    return (
        <div className="card card-custom">
            <div
                className='card-header border-0'
            >
                <div className='card-title'>
                    <h3 className='fw-bolder'>Add Role</h3>
                </div>
            </div>
            <div className="card-body">
                <div className='row '>
                    <label
                        htmlFor="exampleFormControlInput1"
                        className="required col-lg-4 col-form-label fw-bold fs-6"
                    >
                        Role Name
                    </label>
                    <div className='col-lg-8 fv-row'>
                        <Form.Control
                            className="form-control form-control-solid mx-5"
                            style={{ width: "100%" }}
                            type="text"
                            onChange={(e) => setRoleName(e.target.value)}
                            value={roleName}
                        />
                    </div>
                </div>
            </div>
            <div className="card-body">
                <div className='row mb-6'>
                    <label className='required col-lg-4 col-form-label fw-bold fs-6'>
                        <span>Status</span>
                    </label>
                    <div className='col-lg-8 fv-row'>
                        <select
                            className='form-control form-control-solid mb-5 mx-5'
                            onChange={(e) => setStatus(e.target.value)}
                            value={status}
                        >
                            <option value='1'>Active</option>
                            <option value='0'>Inactive</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="card-header">
                <div className="container">
                    <div className="row">
                        <div className="col-3">
                            <div className="p-3 mt-10">
                                <h4 className="card-title">Global Role & Permission</h4>
                            </div>
                        </div>
                        <div className="col-9">
                            <div className="p-3">
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            {["View", "Create", "Update", "Delete", "Logs"].map((option) => (
                                                <td className="text-left fw-bold">
                                                    <label key={option}>{option}</label>
                                                </td>
                                            ))}
                                        </tr>
                                        <tr>
                                            {["view", "create", "update", "delete", "activity-log"].map((option) => (
                                                <td style={tableCellStyle}>
                                                    <div className="form-check form-check-custom form-check-solid">
                                                        <label key={option}>
                                                            <input
                                                                className="form-check-input me-5"
                                                                type="checkbox"
                                                                name={option}
                                                                checked={globalCheckboxes[option]}
                                                                onChange={() =>
                                                                    handleGlobalCheckboxChange(option)
                                                                }
                                                            />
                                                        </label>
                                                    </div>
                                                </td>
                                            ))}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-body">
                <div className="role-permission" style={{ width: "100%" }}>
                    <div className="container mt-2">
                        {getPermission.map((value) => (
                            <div className="row" key={value.permission_key}>
                                <div className="col-3">
                                    <div className="p-3 mt-6">
                                        <h5>{value.title && value.title.charAt(0).toUpperCase() + value.title.slice(1)}</h5>
                                    </div>
                                </div>
                                <div className="col-9">
                                    <div className="p-3">
                                        <table className="table">
                                            <tbody>
                                                <tr>
                                                    {["view", "create", "update", "delete", "activity-log"].map(
                                                        (option) => (
                                                            <td key={option} style={tableCellStyle}>
                                                                {value[option] ? (
                                                                    <div className="form-check form-check-custom form-check-solid">
                                                                        <label>
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                name={option}
                                                                                checked={
                                                                                    roleCheckboxes[
                                                                                    value.permission_key
                                                                                    ]?.[option]
                                                                                }
                                                                                onChange={(event) =>
                                                                                    handleRoleCheckboxChange(
                                                                                        event,
                                                                                        value.permission_key,
                                                                                        option
                                                                                    )
                                                                                }
                                                                            />
                                                                        </label>
                                                                    </div>
                                                                ) : (
                                                                    <div
                                                                        style={{
                                                                            width: 23,
                                                                            height: 0,
                                                                            overflow: "hidden",
                                                                        }}
                                                                    />
                                                                )}
                                                            </td>
                                                        )
                                                    )}
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="card-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-end">
                            <button className="btn btn-light-dark" onClick={handleSubmit}>
                                Add Role
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default AddRole;
