import clsx from 'clsx'
import { KTIcon, toAbsoluteUrl } from '../../../helpers'
import { HeaderNotificationsMenu, HeaderUserMenu, Search, ThemeModeSwitcher } from '../../../partials'
import { useLayout } from '../../core'
import { useAuth } from '../../../../app/modules/auth'
const itemClass = 'ms-1 ms-md-4'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px'
const userAvatarClass = 'symbol-35px'
const btnIconClass = 'fs-2'
const Navbar = () => {
  const { auth } = useAuth();
  const profileData: any = (() => {
    const data = localStorage.getItem('kt-auth-react-v');
    try {
      return data ? JSON.parse(data) : null;
    } catch (error) {
      console.error('Error parsing kt-auth-react-v from localStorage:', error);
      return null;
    }
  })();  
  const {config} = useLayout()
  return (
    <div className='app-navbar flex-shrink-0'>
      {/* commenting for future use  */}
      {/* <div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
        <Search />
      </div> */}
      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div id='kt_activities_toggle' className={btnClass}>
          <KTIcon iconName='chart-simple' className={btnIconClass} />
        </div>
      </div> */}
      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={btnClass}
        >
          <KTIcon iconName='element-plus' className={btnIconClass} />
        </div>
        <HeaderNotificationsMenu />
      </div> */}
      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div className={clsx('position-relative', btnClass)} id='kt_drawer_chat_toggle'>
          <KTIcon iconName='message-text-2' className={btnIconClass} />
          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' />
        </div>
      </div> */}
      <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div>
      <div className={clsx('app-navbar-item', itemClass)}>
        <div
           className={clsx('cursor-pointer symbol', userAvatarClass)}
           data-kt-menu-trigger="{default: 'click'}"
           data-kt-menu-attach='parent'
           data-kt-menu-placement='bottom-end'
        >
         {profileData&& profileData.user.profile_picture===null? (
           <div
           className='symbol symbol-fixed position-relative'
           style={{
             width: '40px', // Set the outer container width
             height: '40px', // Set the outer container height
             borderRadius: '50%', // Make the container circular
             overflow: 'hidden', // Ensure the image doesn't exceed container bounds
           }}
         >
           <img
             src={toAbsoluteUrl('/media/avatars/blank.png')}
             alt='Metornic'
             style={{
               width: '100%', // Image takes full width of the container
               height: '100%', // Image takes full height of the container
               objectFit: 'cover', // Ensure the image is cropped to fit inside
             }}
           />
         </div> 
          ) : (
            <div
            className='symbol symbol-fixed position-relative'
            style={{
              width: '40px', // Set the outer container width
              height: '40px', // Set the outer container height
              borderRadius: '50%', // Make the container circular
              overflow: 'hidden', // Ensure the image doesn't exceed container bounds
            }}
          >
            <img
              src={profileData.user.profile_picture}
              alt='Metornic'
              style={{
                width: '100%', // Image takes full width of the container
                height: '100%', // Image takes full height of the container
                objectFit: 'cover', // Ensure the image is cropped to fit inside
              }}
            />
          </div>
          )}
        </div>
        <HeaderUserMenu />
      </div>
      {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
            // id='kt_app_sidebar_mobile_toggle'
          >
            <KTIcon iconName='text-align-left' className={btnIconClass} />
          </div>
        </div>
      )}
    </div>
  )
}
export { Navbar }
