import React, { ChangeEvent, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { add_user, get_tenant_setting, add_tenant_user, verify_user } from '../api/usersApi';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { addUser } from '../../../modules/accounts/components/settings/SettingsModel';
import secureLocalStorage from 'react-secure-storage';
import { filter_role_by_status } from '../roles/api/rolesApi';
import { validateImage } from '../../../helper-functions/ImageValidation';
type User = {
  id: number;
  department_id: string;
  department_name: string | undefined;
  title: string | undefined;
  firstname: string | undefined;
  middlename: string | undefined;
  lastname: string | undefined;
  telephone: string | undefined;
  email: string | undefined;
  password: string | undefined;
  mobile: string | undefined;
  picture: string | undefined;
  role: string | undefined;
  password_confirmation: string | undefined;
  status: number | undefined;
  created_at: string;
  deleted_at: string;
  updated_at: string;
};
const initialValues: addUser = {
  title: "",
  address:"",
  firstname: "",
  middlename: "",
  lastname: "",
  telephone: "",
  email: "",
  password: "",
  mobile: "",
  picture:"",
  role: "",
  status: 1
};
interface userRoleType {
  id: number;
  name: string;
};
interface Department {
  id: number;
  name: string;
};
const numberRegex = /^[+0-9]+$/;
const userSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  firstname: Yup.string().required('First Name is required'),
  lastname: Yup.string().required('Last Name is required'),
  email: Yup.string()
    .required('Email is required')
    .matches(/^[A-Za-z0-9._%+-]+@([A-Za-z0-9.-]+\.[A-Za-z]{2,4})$/, 'Invalid email address'),
    telephone: Yup.string()
    .matches(numberRegex, 'Telephone number must be in numeric value'),
  mobile: Yup.string()
    .required('Mobile Number is required')
    .matches(numberRegex, 'Mobile Number must be in numeric value'),
  role: Yup.string().required('Role is required'),
});
function AddUser() {
  const [selectedUser, setSelectedUser] = useState<User | null>();
  const [loading, setLoading] = useState(false);
  const [department, setDepartment] = useState<Department[]>([]);
  const [roleType, setRoleType] = useState<userRoleType[]>([]);
  const [organisationApplicationData, setOrganisationApplicationData] = useState<{
    application_id: number;
    organization_id: number;
  }>({
    application_id: 0,
    organization_id: 0,
  });
  const [userPicture, setUserPicture] = useState<File | null>(null);
  const navigate = useNavigate();
  const { data } = useQuery(
    'filter_role_by_status', 
    () => filter_role_by_status(1),
    { refetchOnWindowFocus: false }
  );
  useEffect(() => {
    if (data) {
      setRoleType(data.data as userRoleType[]);
    }
  }, [data]);
  useEffect(() => {
    const fetchTenantSetting = async () => {
      try {
        const result = await get_tenant_setting();
        console.log(result);
        setOrganisationApplicationData(result);
      } catch (error) {
        console.error('Error fetching tenant settings:', error);
      }
    };

    fetchTenantSetting();
  }, []);
  // const { data: departmentData } = useQuery('department', get_active_department, {refetchOnWindowFocus: false});
  // useEffect(() => {
  //   if (departmentData) {
  //     setDepartment(departmentData.data as Department[]);
  //   };
  // }, [departmentData]);
  const formik = useFormik<addUser>({
    initialValues,
    validationSchema: userSchema,
    onSubmit: async (values) => {
      setLoading(true);

    // Extract `frontend_domain` from window location
    const currentURL = window.location.hostname; // 'abc.com' will be extracted
    const frontendDomain = currentURL.replace(/^www\./, ''); // Remove 'www.' if it exists

    // Get `backend_domain` from secureLocalStorage and remove `http://` or `https://`
    const backendDomain = (typeof secureLocalStorage.getItem('baseURL') === 'string'
      ? secureLocalStorage.getItem('baseURL') as string
      : ''
    )?.replace(/^(https?:\/\/)/, '');

    // Get `organization_id` and `application_id` from organisationApplicationData state
    const { organization_id, application_id } = organisationApplicationData;

    // Create data for `add_tenant_user` API
    const tenantUserData = {
      first_name: values.firstname,
      middle_name: values.middlename,
      last_name: values.lastname,
      email: values.email,
      mobile_no: values.mobile,
      organization_id,
      application_id,
      frontend_domain: frontendDomain,
      domain: backendDomain,
    };
      // Create a FormData object
      const formData:any= new FormData();
      formData.append('address', values.address);
      formData.append('title', values.title);
      formData.append('firstname', values.firstname);
      formData.append('middlename', values.middlename);
      formData.append('lastname', values.lastname);
      formData.append('telephone', values.telephone);
      formData.append('email', values.email);
      formData.append('mobile', values.mobile);
      formData.append('role', values.role);
      formData.append('status', values.status);
      // formData.append('status', values.status);
      if (userPicture) {
        formData.append('picture', userPicture);
      };
      try {
        // Call the first API (add_user)
        const userResult = await add_user(formData);
        toast.success(userResult.message, {
          position: 'top-right',
          autoClose: 3000,
        });
  
        // Call the second API (add_tenant_user) after the first one succeeds
        const tenantResult = await add_tenant_user(tenantUserData);
        // toast.success(tenantResult.message, {
        //   position: 'top-right',
        //   autoClose: 3000,
        // });
  
        // Navigate to the user list
        navigate('/users/users-list');
      } catch (error: any) {
        if (error.response) {
          const errorMessage = error.response.data.message;
          toast.error(errorMessage, {
            position: 'top-right',
            autoClose: 2000,
          });
        }
      } finally {
        setLoading(false);
      }
    },
  });
  const handleImageChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    logoType: string,
    MAX_FILE_SIZE: number,
    ALLOWED_TYPES: string[],
    MAX_DIMENSIONS: { [key: string]: { width: number; height: number } }
  ) => {
    const newFile = e.target.files?.[0];
    if (newFile) {
      // Call the validation function
      validateImage(
        newFile,
        logoType,
        (validFileUrl) => {
          // If valid, update the state with the image URL
          if (logoType === 'picture') {
            setUserPicture(newFile);
          }
        },
        MAX_FILE_SIZE, // File size limit in MB
        ALLOWED_TYPES, // Allowed file types
        MAX_DIMENSIONS // Maximum dimensions per logo type
      );
    }
  };
  function verifyUser(): void {
    const userEmailInput = (document.getElementById('user_email_input') as HTMLInputElement)?.value;
    fetchUserData(userEmailInput)
  }
  const fetchUserData = async (email) => {
    try {
      // Call the first API (add_user)
      const response = await verify_user(email);
      setSelectedUser(response.data)
      formik.setFieldValue('firstname', response.data?.first_name || '');
      formik.setFieldValue('email', response.data?.email || '');
      formik.setFieldValue('middlename', response.data?.middle_name || '');
      formik.setFieldValue('lastname', response.data?.last_name || '');
      formik.setFieldValue('mobile', response.data?.mobile_no || '');
      toast.success(response.message, {
        position: 'top-right',
        autoClose: 3000,
      });

    } catch (error: any) {
      if (error.response) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage, {
          position: 'top-right',
          autoClose: 2000,
        });
        formik.setFieldValue('firstname', '');
        formik.setFieldValue('email', '');
        formik.setFieldValue('middlename', '');
        formik.setFieldValue('lastname', '');
        formik.setFieldValue('mobile', '');
      }
    } finally {
      // setLoading(false);
    }
  };

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Add User</h3>
        </div>
      </div>
      <div id='kt_account_profile_details' className='collapse show'>
        <div className='card-body border-top p-9'>
        <div className='row mb-6'>
            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Email</label>
            <div className='col-lg-6 fv-row'>
              <input
                type='email'
                className='form-control form-control-lg form-control-solid'
                placeholder='Email'
                id='user_email_input'
                {...formik.getFieldProps('email')}
                onChange={formik.handleChange}
              />
              {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.email}</div>
                </div>
              )}
            </div>
            <div className='col-lg-2 fv-row'>
            <button
                data-toggle='tooltip'
                data-placement='bottom'
                title='Verify User'
                type='button'
                className='btn btn-primary'
                onClick={() => verifyUser()}
              >
               Verify User
            </button>
            </div>
          </div>
        <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>
              <span className='required'>Role</span>
            </label>
            <div className='col-lg-8 fv-row'>
              <select
                className='form-select form-select-solid form-select-lg fw-bold'
                {...formik.getFieldProps('role')}
              >
                <option value=''>Select a Role...</option>
                {roleType.map((rolType) => (
                  <option key={rolType.id} value={rolType.name}>
                    {rolType.name}
                  </option>
                ))}
              </select>
              {formik.touched.role && formik.errors.role && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.role}</div>
                </div>
              )}
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>
              <span className='required'>Title</span>
            </label>
            <div className='col-lg-8 fv-row'>
              <select
                className='form-select form-select-solid form-select-lg fw-bold'
                {...formik.getFieldProps('title')}
                value={formik.values.title}
                onChange={(e) => formik.setFieldValue('title', e.target.value)}
                onBlur={formik.handleBlur}
              >
                <option value=''>Select a Title...</option>
                <option value='Mr.'>Mr.</option>
                <option value='Mrs.'>Mrs.</option>
                <option value='Ms.'>Ms.</option>
              </select>
              {formik.touched.title && formik.errors.title && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.title}</div>
                </div>
              )}
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label required fw-bold fs-6'>First Name</label>
            <div className='col-lg-8 fv-row'>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder='First Name'
                {...formik.getFieldProps('firstname')}
                onChange={formik.handleChange}
              />
              {formik.touched.firstname && formik.errors.firstname && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.firstname}</div>
                </div>
              )}
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>Middle Name</label>
            <div className='col-lg-8 fv-row'>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder='Middle Name'
                {...formik.getFieldProps('middlename')}
                onChange={formik.handleChange}
              />
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Last Name</label>
            <div className='col-lg-8 fv-row'>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder='Last Name'
                {...formik.getFieldProps('lastname')}
                onChange={formik.handleChange}
              />
              {formik.touched.lastname && formik.errors.lastname && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.lastname}</div>
                </div>
              )}
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>
              <span>Address</span>
            </label>
            <div className='col-lg-8 fv-row'>
            <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder='Address'
                {...formik.getFieldProps('address')}
                onChange={formik.handleChange}
              />
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>Telephone</label>
            <div className='col-lg-8 fv-row'>
              <input
                type='tel'
                className='form-control form-control-lg form-control-solid'
                placeholder='Telephone'
                {...formik.getFieldProps('telephone')}
                onChange={formik.handleChange}
              />
              {formik.touched.telephone && formik.errors.telephone && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.telephone}</div>
                </div>
              )}
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>
              <span className='required'>Phone Number</span>
            </label>

            <div className='col-lg-8 fv-row'>
              <input
                type='tel'
                className='form-control form-control-lg form-control-solid'
                placeholder='Mobile number'
                {...formik.getFieldProps('mobile')}
              />
              {formik.touched.mobile && formik.errors.mobile && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.mobile}</div>
                </div>
              )}
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>Picture</label>
            <div className='col-lg-8 fv-row'>
              <input
                type='file'
                className='form-control form-control-lg form-control-solid'
                onChange={(e) => handleImageChange(
                  e,
                  'picture',
                  2, // Max file size (2MB)
                  ['image/jpeg', 'image/jpg', 'image/png'], // Allowed types
                  {
                    picture: { width: 200, height: 200 }
                  } // Max dimensions
                )}
                accept="image/jpeg, image/jpg, image/png"
                name='picture'
              />
              <div className='text-danger mt-2'>
              Recommended size: 200 x 200 px, max file size: 2MB, and accepted file types: jpg, jpeg, or png only.
              </div>
            </div>
          </div>
          <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span>Status</span>
              </label>
              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg fw-bold'
                  {...formik.getFieldProps('status')}
                >
                  <option value={1}>Active</option>
                  <option value={0}>Inactive</option>
                </select>                
              </div>
            </div>
        </div>
      </div>
      <div className='card-footer d-flex justify-content-end py-6 px-9'>
        <button
          data-toggle='tooltip'
          data-placement='bottom'
          title='Add New User'
          type='button'
          className='btn btn-primary'
          disabled={loading}
          onClick={() => formik.handleSubmit()}
        >
          {!loading && 'Add User'}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </div>
  )
}
export default AddUser;