import axios from 'axios'
import {UserModel} from './_models'
import { getBaseURL } from '../../../pages/api-helper/api-header-config';
import secureLocalStorage from 'react-secure-storage';
const API_URL = process.env.REACT_APP_API_URL;
const API_URL_CENTRAL = process.env.REACT_APP_API_URL_CENTRAL;
let current_url = window.location.href;
getBaseURL(current_url);
export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL_CENTRAL}/api/verify-token`
export const GET_PEOPLE_BY_ACCESSTOKEN_URL = `${secureLocalStorage.getItem("baseURL")}/api/people-verify-token`
export const LOGIN_URL = `${API_URL_CENTRAL}/api/login`
export const PEOPLE_LOGIN_URL = `${secureLocalStorage.getItem("baseURL")}/api/people-login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL_CENTRAL}/api/forget-password`
export const REQUEST_PEOPLE_PASSWORD_URL = `${API_URL}/api/people-forgot-password`
// get theme
export const get_theme_flag = async () => {
  const response = await axios.get(secureLocalStorage.getItem("baseURL") + "/api/get-user-theme-mode")
  return response.data;
}
// all permissions of logged in user
export const get_user_permissions = async () => {
  let response = await axios.get(`${secureLocalStorage.getItem("baseURL")}/api/user-permissions`);
  return response.data;
}
export async function getOrganisationMedia() {
  let baseURL:any= secureLocalStorage.getItem("baseURL");
  // Wait for baseURL if not set
  if (!baseURL) {
    console.warn("baseURL not set yet. Waiting...");
    baseURL = await waitForBaseURL();
  }
  return axios.get(`${baseURL}/api/get-logos-images`);
}

function waitForBaseURL() {
  return new Promise((resolve) => {
    const interval = setInterval(() => {
      const url = secureLocalStorage.getItem("baseURL");
      if (url) {
        clearInterval(interval);
        resolve(url);
      }
    }, 100); // Check every 100ms
  });
}
// Server should return AuthModel
export async function login(email: string, password: string) {
  const response = await axios.post(LOGIN_URL, {
    email,
    password,
  })
  return response?.data
};
export async function peopleLogin(email: string, password: string) {
  const response = await axios.post(PEOPLE_LOGIN_URL, {
    email,
    password,
  })
  return response?.data
};
// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}
// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}
export function requestPeoplePassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PEOPLE_PASSWORD_URL, {
    email,
  })
}
export function getUserByToken(token: string) {
  return axios.post<UserModel>(`${secureLocalStorage.getItem("baseURL")}/api/verify-token`, {
    access_token: token,
  })
}
export function getPeopleByToken(token: string) {
  return axios.post<UserModel>(GET_PEOPLE_BY_ACCESSTOKEN_URL, {
    access_token: token,
  })
}
// Sign Out
export function signout(access_token: string) {
  const headers = {
    Authorization: `Bearer ${access_token}`,
  };
    return axios.post(`${secureLocalStorage.getItem("baseURL")}/api/logout`, {}, { headers });
}
// Sign Out
export function peoplesignout(access_token: string) {
  const headers = {
    Authorization: `Bearer ${access_token}`,
  };
    return axios.post(`${API_URL}/api/people-logout`, {}, { headers });
}