import axios from "axios";
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");
// Add New Organization
export const add_add_on_settings = async (values:object) => {
  let response = await axios.post(baseURL + "/api/create-addon-settings",values);
  return response.data;
}
  // Get All General Settings
  export const get_add_on_settings = async () => {
    let response = await axios.get(baseURL + "/api/get-addon-settings");
    return response.data;
  };
  export const get_add_on_settings_logs = async () => {
    let response = await axios.get(baseURL + "/api/activity-logs-for-addon-setting");
    return response.data;
  };
