import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import secureLocalStorage from "react-secure-storage";
import { get_all_general_settings } from '../../../app/pages/organization-settings/api/organizationSettingApi';
const baseURL = process.env.REACT_APP_API_URL_CENTRAL;
interface User {
    access_token: string;
}

function LogInSplashScreen() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const iframeRefs = useRef<(HTMLIFrameElement | null)[]>([]); // Array of refs for multiple iframes
    const [domains, setDomains] = useState<string[]>([]);
    // const domains = ["https://customer1.wrikooffice.staginghosts.com/store-token", "https://customer2.wrikooffice.staginghosts.com/store-token", "https://customer3.wrikooffice.staginghosts.com/store-token", "https://customer4.wrikooffice.staginghosts.com/store-token"]; // List of iframe URLs
const theme_mode =localStorage.getItem("kt_theme_mode_value");
    const handleIframeLoad = (index: number) => {
        const user = JSON.parse(localStorage.getItem('kt-auth-react-v') || 'null') as User | null; // Type assertion
        if (user) {
            const expirationTime = 3600000; // 1 hour in milliseconds
            storeToken(user, expirationTime, index);
            console.log(`Iframe ${index + 1} loaded.`);
        }
        
        // Check if all iframes have loaded
        const loadedIframes = iframeRefs.current.filter(iframe => iframe?.contentWindow).length;
        if (loadedIframes === domains.length) {
            setIsLoading(false); // Hide loading screen once all iframes have loaded
            setTimeout(() => {
                navigate('/dashboard');
            }, 3000);
        }
    };

    const storeToken = (token: User, expirationTime: number, index: number) => {
        localStorage.setItem('kt-auth-react-v', JSON.stringify(token));
        localStorage.setItem('kt-auth-react-v-expiration', (Date.now() + expirationTime).toString()); // Convert to string

        const iframe = iframeRefs.current[index];
        if (iframe && iframe.contentWindow) {
            try {
                iframe.contentWindow.postMessage(
                    { token, expiration: Date.now() + expirationTime },
                    domains[index]
                );
                console.log(`Message sent to iframe ${index + 1}`);
            } catch (error) {
                console.error(`Error sending message to iframe ${index + 1}:`, error);
            }
        }
    };

    useEffect(() => {
        const fetchDomains = async () => {
            try {
                const lsValue = localStorage.getItem("kt-auth-react-v");
                let userId: string | undefined;
                if (lsValue) {
                    const parsedValue = JSON.parse(lsValue) as { user: { id: string } };
                    userId = parsedValue.user.id;
                } else {
                    console.warn("User information is missing in localStorage.");
                }
                if (lsValue) {
                const parsedValue= JSON.parse(lsValue) as { user:any };
                if(!parsedValue.user?.is_people){
                    const response = await axios.get(`${baseURL}/api/get-user-domains/${userId}`);
                    const domains = response.data.data;
                    if (domains && domains.length > 0) {
                        setDomains(domains); // Set domains if data is available
                    } else {
                        setIsLoading(false); // Stop loading if no domains are returned
                        setTimeout(() => {
                            navigate('/dashboard');
                        }, 3000);
                    }
                    const getGeneralSettingsFlag = await get_all_general_settings();
        secureLocalStorage.setItem("general_settings", getGeneralSettingsFlag.data)
                    setDomains(response.data.data); // Assuming the API returns an object with a domains array
                }else{
                    setIsLoading(false); // Stop loading if no domains are returned
                    setTimeout(() => {
                        navigate('/dashboard');
                    }, 100);
                }
            }
               
            } catch (error) {
                console.error("Error fetching domains:", error);
                setIsLoading(false);
            }
        };

        fetchDomains();
        const maxLoadingTimeout = setTimeout(() => {
            setIsLoading(false);
            console.warn("Iframes took too long to load.");
        }, 500); // Adjust time as per requirement

        return () => clearTimeout(maxLoadingTimeout);
    }, []);

    return (
        <div className="d-flex justify-content-center align-items-center vh-100">
            {domains.map((domain, index) => (
                <iframe
                    key={index}
                    ref={(el) => (iframeRefs.current[index] = el)} // Assign ref with null check
                    src={domain}
                    onLoad={() => handleIframeLoad(index)}
                    style={{ display: 'none' }} // Hidden iframe
                    title={`Hidden Iframe ${index + 1}`}
                />
            ))}
            {/* {isLoading && ( */}
            <>
                {
                    (!localStorage.getItem("white_logo") || localStorage.getItem("white_logo") === 'null') ? (
                       <>
                       {
                        theme_mode=="dark"?(
                        <h1 className='text-white fs-2qx fw-bolder text-center'>
                            Wriko Calendar
                        </h1>
                        ):(
                        <h1 className='text-dark fs-2qx fw-bolder text-center'>
                            Wriko Calendar
                        </h1>
                        )
                       }
                       </>  
                    ) : (
                        <img
                        alt='Wriko Calendar'
                            src={localStorage.getItem('white_logo') || undefined}
                            className='h-50px'
                        />
                    )
                }
            </>
            {/* )} */}
        </div>
    );
}

export default LogInSplashScreen;