import React, { useEffect, useState } from 'react'
import { allPeople } from '../../../modules/accounts/components/settings/SettingsModel';
import { useLocation, useNavigate } from 'react-router';
import { useQuery } from 'react-query';
import { get_single_person } from '../api/peopleApi';
import { getSettingValue } from '../../../helper-functions/general_settings_helper';

function ViewPeople() {
  const [singlePerson, setSinglePerson] = useState<allPeople | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const id = (location.state as { id: number }).id;
  const { data: singlePersonData, isLoading: isSinglePersonLoading } = useQuery(
    ['singlePerson', id],
    () => get_single_person(id!),
    {
      refetchOnWindowFocus: false,
      enabled: !!id,
    }
  );
  useEffect(() => {
    if (singlePersonData) {
      setSinglePerson(singlePersonData.data as allPeople);
    }
  }, [singlePersonData]);
  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0'
      >
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>View Person</span>
        </h3>
        <div
          className='card-toolbar'
        >
          <button
            data-toggle="tooltip" data-placement="bottom" title="Navigate to List Products"
            className='btn btn-sm btn-light-dark fs-5'
            onClick={() => navigate('/people/people-list')}
          >
            List of People
          </button>
        </div>
      </div>
      <div id='kt_account_profile_details' className='collapse show'>
        <div className='card-body border-top p-9'>
          {isSinglePersonLoading ? (
            <tr>
              <td colSpan={6}>
                <div className='text-center'>
                  <div className='spinner-border' role='status'>
                    <span className='visually-hidden'>Loading...</span>
                  </div>
                </div>
              </td>
            </tr>
          ) : singlePerson ? (
            <>
            {/* Use This Commented code in next Pr */}
              <div className='form-group'>
              <div className="row mb-6">
  <label className="col-lg-4 col-form-label fw-bold fs-6">
                      <span className="required">Category</span>
                    </label>
                    <div className="col-lg-8 fv-row">
                      <label className='form-control form-control-lg form-control-solid'>
                        {singlePerson.selected_category_ids.map((category, index) => (
                          <span key={category.id} className='badge badge-secondary mx-1'>
                            {category.title}
                          </span>
                        ))}
                      </label>
                    </div>
</div>
                <div className='row mb-6'>
                  <label className='col-lg-4 required col-form-label fw-bold fs-6 '>
                    <span>Subcategory</span>
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <label className='form-control form-control-lg form-control-solid'>
                      {singlePerson.sub_categories.map((subcategory, index) => (
                        <span key={subcategory.sub_category_id} className='badge badge-secondary mx-1'>
                          {subcategory.sub_category_title}
                        </span>
                      ))}
                    </label>
                  </div>
                </div>
              </div>
              <div className='form-group'>
                <div className='row mb-6'>
                  <label className='col-lg-4 required col-form-label fw-bold fs-6'>
                    <span>Title</span>
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <label className='form-control form-control-lg form-control-solid'>
                      {singlePerson.title}
                    </label>
                  </div>
                </div>
              </div>
              <div className='form-group'>
                <div className='row mb-6'>
                  <label className='col-lg-4 required col-form-label fw-bold fs-6 '>
                    <span>First Name</span>
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <label className='form-control form-control-lg form-control-solid'>
                      {singlePerson.first_name}
                    </label>
                  </div>
                </div>
              </div>
              <div className='form-group'>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span>Middle Name</span>
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <label className='form-control form-control-lg form-control-solid'>
                      {singlePerson.middle_name}
                    </label>
                  </div>
                </div>
              </div>
              <div className='form-group'>
                <div className='row mb-6'>
                  <label className='col-lg-4 required col-form-label fw-bold fs-6'>
                    <span>Last Name</span>
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <label className='form-control form-control-lg form-control-solid'>
                      {singlePerson.last_name}
                    </label>
                  </div>
                </div>
              </div>
              <div className='form-group'>
                <div className='row mb-6'>
                  <label className='col-lg-4 required col-form-label fw-bold fs-6'>
                    <span>Address 1</span>
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <label className='form-control form-control-lg form-control-solid'>
                      {singlePerson.address_line_1}
                    </label>
                  </div>
                </div>
              </div>
              <div className='form-group'>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span>Address 2</span>
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <label className='form-control form-control-lg form-control-solid'>
                      {singlePerson.address_line_2}
                    </label>
                  </div>
                </div>
              </div>
              <div className='form-group'>
                <div className='row mb-6'>
                  <label className='col-lg-4 required col-form-label fw-bold fs-6'>
                    <span>Town</span>
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <label className='form-control form-control-lg form-control-solid'>
                      {singlePerson.town}
                    </label>
                  </div>
                </div>
              </div>
              <div className='form-group'>
                <div className='row mb-6'>
                  <label className='col-lg-4 required col-form-label fw-bold fs-6'>
                    <span>City</span>
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <label className='form-control form-control-lg form-control-solid'>
                      {singlePerson.city}
                    </label>
                  </div>
                </div>
              </div>
              <div className='form-group'>
                <div className='row mb-6'>
                  <label className='col-lg-4 required col-form-label fw-bold fs-6'>
                    <span>Country</span>
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <label className='form-control form-control-lg form-control-solid'>
                      {singlePerson.country}
                    </label>
                  </div>
                </div>
              </div>
              <div className='form-group'>
                <div className='row mb-6'>
                  <label className='col-lg-4 required col-form-label fw-bold fs-6'>
                    <span>Postal Code</span>
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <label className='form-control form-control-lg form-control-solid'>
                      {singlePerson.postal_code}
                    </label>
                  </div>
                </div>
              </div>
              <div className='form-group'>
                <div className='row mb-6'>
                  <label className='col-lg-4 required col-form-label fw-bold fs-6'>
                    <span>Mobile Number</span>
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <label className='form-control form-control-lg form-control-solid'>
                      {singlePerson.mobile_number}
                    </label>
                  </div>
                </div>
              </div>
              <div className='form-group'>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span>Phone Number</span>
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <label className='form-control form-control-lg form-control-solid'>
                      {singlePerson.phone_number}
                    </label>
                  </div>
                </div>
              </div>
              <div className='form-group'>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span>Picture</span>
                  </label>
                  <div className='col-lg-8 fv-row'>
                    {singlePerson.picture ? (
                      <img src={singlePerson.picture} alt='Profile'  className='form-control form-control-lg form-control-solid' style={{ width: '200px', height: '200px' }} />
                    ) : (
                      <span  className='form-control form-control-lg form-control-solid'>No Image</span>
                    )}
                  </div>
                </div>
              </div>
              <div className='form-group'>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span>Other Information</span>
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <label className='form-control form-control-lg form-control-solid'>
                      {singlePerson.other_information}
                    </label>
                  </div>
                </div>
              </div>
              <div className='form-group'>
                <div className='row mb-6'>
                  <label className='col-lg-4 required col-form-label fw-bold fs-6'>
                    <span>Email</span>
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <label className='form-control form-control-lg form-control-solid'>
                      {singlePerson.email}
                    </label>
                  </div>
                </div>
              </div>
              <div className='form-group'>
                <div className='row mb-6'>
                  <label className='col-lg-4 required col-form-label fw-bold fs-6 '>
                    <span>Appointment Rate ({getSettingValue('currency_symbol')})</span>
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <label className='form-control form-control-lg form-control-solid'>
                      {singlePerson.appointment_rate}
                    </label>
                  </div>
                </div>
              </div>
              <div className='form-group'>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span>Available Platforms</span>
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <label className='form-control form-control-lg form-control-solid'>
                      {singlePerson.people_platforms.map((platform) => (
                        <span key={platform.location_id} className='badge badge-secondary mx-1'>
                          {platform.platform_name}
                        </span>
                      ))}
                    </label>
                  </div>
                </div>
              </div>
              <div className='form-group'>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span>Available Locations</span>
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <label className='form-control form-control-lg form-control-solid'>
                      {singlePerson.people_locations.map((platform) => (
                        <span key={platform.location_id} className='badge badge-secondary mx-1'>
                          {platform.platform_name}
                        </span>
                      ))}
                    </label>
                  </div>
                </div>
              </div>
              <div className='form-group'>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span>Status</span>
                  </label>
                  <div className='col-lg-8 fv-row'>
                    {singlePerson.status === 1 ? (
                      <label className='form-control form-control-lg form-control-solid'>
                        Active
                      </label>
                    ) : (
                      <label className='form-control form-control-lg form-control-solid'>
                        Inactive
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default ViewPeople