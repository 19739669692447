import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'
import {ThemeModeProvider} from '../_metronic/partials'
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "flatpickr/dist/themes/material_blue.css";
import { getSettingValue } from './helper-functions/general_settings_helper'

const App = () => {
  const onLoadCustomFunction = async () => {
    try {
      const head = document.querySelector('head');
      const link:any= document.createElement('link') as HTMLLinkElement;
      link.type = 'image/png';
      link.rel = 'icon';
      link.href = localStorage.getItem('favicon');
      link.setAttribute('sizes', '16x16');
      head?.appendChild(link);
    } catch (error) {
      console.error(error);
    }
  }
  onLoadCustomFunction();
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <ToastContainer />
      <I18nProvider>
        <LayoutProvider>
          <ThemeModeProvider>
            <AuthInit>
              <Outlet />
              <MasterInit />
            </AuthInit>
          </ThemeModeProvider>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
